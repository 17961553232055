<template>

    <section class="carrinho">

        <CheckAcesso />

        <section class="main-minha_conta">

            <div class="container">                         
                <div class="row">
                    <!-- Sidenav -->
                    <div class="col-lg-2" style="background-color: #cccccc;">
                        <ul class="lista-sidebar_links" style="list-style-type: none;padding:10px;">
                            <li style="border-bottom: 1px solid #f9f9f9 ;padding:10px;">
                                <router-link to="/meusDados" class="texto">Meus Dados</router-link>
                            </li>
                            <li style="border-bottom: 1px solid #f9f9f9 ;padding:10px;">
                                <router-link to="/meusPedidos" class="texto">Meus Pedidos</router-link>
                            </li>
                        </ul>
                    </div>
                    <!-- Main -->
                    <div class="col-lg-10 col-xl-9 offset-xl-1 mt-5 mt-lg-0">
                        <div class="col">
                                <ul class="lista-pedidos border-bottom py-3 mb-3" style="list-style-type: none;">
                                    <li>
                                        <div class="row">
                                            <div class="col-6 col-md-3 col-lg-3 mb-4 mb-md-0">
                                                <div class="pedido_id">
                                                    <i class="fa fa-clipboard text-success fa-2x"></i>
                                                    <div>
                                                        <h6 class="mb-0">Pedido: {{ Pedido.pedidoId }}</h6>
                                                        <div class="pedido_dados">Pgto: {{ Pedido.formaPgto }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-2 col-lg-2 d-flex align-items-center">
                                                <div class="pedido_dados">{{ FormataData(Pedido.dataPedido) }}</div>
                                            </div>
                                            <div class="col-md-2 col-lg-7 d-flex align-items-center">
                                                <ul style="list-style-type: none;">
                                                    <li v-for="itens in Pedido.listaPedidoItem" :key="itens.pedidoItemId" style="font-size: 12px;">
                                                        {{ itens.produto.nomeProduto }}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                        </div>
                        <h3 class="titulo-principal text-left mb-4">Envio de Documentos</h3>


                        <!-- Lista de arquivos -->
                        <div class="row">
                            <div class="col-lg-6 mb-3">
                                <h6 class="subtitulo h5 mb-4"><strong>Etapa {{ IdEtapa }}) {{ NomeEtapa }}</strong></h6>
                            </div>
                            <div class="col-lg-6 mb-3">
                                <h6 class="subtitulo h5 mb-4">Prazo Até: <strong>{{ PrazoEtapa }}</strong></h6>
                            </div>
                            <div class="col-lg-12 mb-3">
                                <span v-html="TextoEtapa1"></span>
                            </div>
                            <div class="col-lg-12 mb-3">
                                <span v-html="TextoEtapa2"></span>
                            </div>
                            <div class="col-lg-12" v-if="IdEtapa!= 2 && IdEtapa!= 4 && IdEtapa!= 6 && IdEtapa!= 7">
                                <form>
                                    <div class="row">
                                        <div class="col-md-4 mb-3">
                                            <input v-model="nomeArquivo" type="text" class="form-control"
                                                placeholder="Nome Documento">
                                        </div>
                                        <div class="col-md-5">
                                            <input id="arquivo" type="file" class="" style="padding-top: 5px;" />
                                        </div>
                                        <div class="col-md-3">
                                            <button @click="carregaFoto()" class="btn btn-danger">Gravar Doc</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="row mt-5">
                                <div class="col-md-12">
                                    <h6 v-if="Arquivos!=''" class="subtitulo h5 mb-4">Documentos Enviados</h6>
                                    <!-- Lista de Pedidos -->
                                    <div class="row">
                                        <div class="col-md-2 col-lg-2 d-flex align-items-center">
                                            <div class="pedido_dados">Etapa</div>
                                        </div>
                                        <div class="col-md-2 col-lg-2 d-flex align-items-center">
                                            <div class="pedido_dados">Documento</div>
                                        </div>
                                        <div class="col-md-2 col-lg-2 d-flex align-items-center">
                                            <div class="pedido_dados"> Arquivo</div>
                                        </div>
                                        <div class="col-md-2 col-lg-2 d-flex align-items-center">
                                            <div class="pedido_dados"> Analisado </div>
                                        </div>
                                        <div class="col-md-2 col-lg-2 d-flex align-items-center">
                                            <div class="pedido_dados"> Aprovado </div>
                                        </div>
                                        <div class="col-md-2 col-lg-2 d-flex align-items-center">
                                            <div class="pedido_dados"> </div>
                                        </div>
                                    </div>
                                    <ul class="listaPedidos" style="list-style-type: none;">
                                        <li v-for="arquivo in Arquivos" :key="arquivo.pedidoEtapasDocumentosId">
                                            <hr>
                                            <div class="row">
                                                <div class="col-md-2 col-lg-2 d-flex align-items-center">
                                                    <div class="pedido_dados">{{ arquivo.nomeEtapa }}</div>
                                                </div>
                                                <div class="col-md-2 col-lg-2 d-flex align-items-center">
                                                    <div class="pedido_dados">{{ arquivo.nomeDocumento }}</div>
                                                </div>
                                                <div class="col-md-2 col-lg-2 d-flex align-items-center">
                                                    <a :href="$Url + arquivo.urlDoc" target="_blank">
                                                        <div style="padding-bottom:10px;">
                                                            <img src="/images/pdfimg.jpg" style="width:30px" />
                                                        </div>
                                                    </a>
                                                </div>
                                                <div class="col-md-2 col-lg-2 d-flex align-items-center">
                                                    <div v-if="arquivo.analisar && !arquivo.aprovado" class="pedido_dados">Enviado</div>
                                                    <div v-if="arquivo.analisar && arquivo.aprovado" class="pedido_dados">Sim</div>
                                                    <div v-if="!arquivo.analisar && !arquivo.aprovado" class="pedido_dados">Não</div>
                                                </div>
                                                <div class="col-md-2 col-lg-2 d-flex align-items-center">
                                                    <div v-if="arquivo.aprovado" class="pedido_dados">Sim</div>
                                                    <div v-else class="pedido_dados"></div>
                                                </div>
                                                <div v-if="!arquivo.analisar && !arquivo.aprovado" class="col-md-2 col-lg-2 d-flex align-items-center">
                                                    <a href="#" data-toggle="tooltip" data-placement="top" @click="ExcluirDocumento(arquivo.pedidoEtapasDocumentosId)" title="Excluir" style="color: black;">
                                                       <i class="fa fa-window-close" style="color: black;"></i> Excluir
                                                     </a>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="row mt-5" v-if="IdEtapa!= 2 && IdEtapa!= 4 && IdEtapa!= 6 && IdEtapa!= 7">
                                <div class="col-md-12" style="text-align: center;">
                                  Já subiu todos os arquivos desta Etapa? se sim clique abaixo para enviar para nossa análise.
                                </div>
                                <div class="col-md-12 mt-3" style="text-align: center;">
                                    <button @click="EnviaAnalise()" class="btn btn-danger">Enviar Documentos para Análise</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </section>

    </section>

</template>

<script>

import CheckAcesso from '@/components/site/shared/CheckAcesso.vue'
import moment from 'moment';

export default {
    components: {
        CheckAcesso,
    },
    data() {
        return {
            ClienteId: 0,
            PedidoId: this.$route.params.id,
            Arquivos: [],
            nomeArquivo: '',
            Etapas: [],
            NomeEtapa: '',
            TextoEtapa1: '',
            TextoEtapa2: '',
            PedidoDocumentos: [],
            DataInicioEtapa: '',
            IdEtapa:1,
            Pedido:{},
        }
    },
    methods: {
        FormataData(value) {
            return moment(value).format('DD/MM/YYYY')
        },
        carregaFoto() {
            let file = document.getElementById("arquivo").files[0];
            let erroarquivo = "";
            if (this.nomeArquivo == "" || this.nomeArquivo == null) {
                this.$mensagem_normal("Favor preencher nome do arquivo que esta enviando.");
            }
            else {
                if (file != null) {
                    var ext = file.name.split(".").pop();

                    if (ext != "jpg" && ext != "png" && ext != "gif" && ext != "jpeg" && ext != "pdf") {
                        erroarquivo =
                            "Por favor selecione uma imagem ('.jpg', '.jpeg', '.png', pdf ou '.gif')";
                    } else {
                        erroarquivo = "";
                    }
                }
                else {
                    erroarquivo = "Por favor selecione uma imagem ('.jpg', '.jpeg', '.png', pdf ou '.gif')";
                }
                if (erroarquivo != null && erroarquivo.length > 0) {
                    this.$mensagem_normal(erroarquivo);
                }
                else {
                    let data = new FormData();
                    if (file != null) {
                        data.append("arquivo", file);
                        this.$http.post(this.$apiUrl + "/PedidoEtapasDocumentos/upload", data).then(
                            (response3) => {
                                // get body data
                                this.FotoCarregada = response3.body;

                                //carrega Pedido Documento
                                let _pedidoEtapaDocumento = {}
                                _pedidoEtapaDocumento = {
                                    UrlDoc: this.FotoCarregada,
                                    NomeDocumento: this.nomeArquivo,
                                    NomeEtapa: this.NomeEtapa,
                                    DataInicio: this.DataInicioEtapa,
                                    DataCarga: moment().format('YYYY-MM-DD'),
                                    Analisar: false,
                                    Aprovado: false,
                                    Pedido: { PedidoId: parseInt(this.PedidoId, 10) },
                                }

                                this.$http
                                    .post(this.$apiUrl + "/PedidoEtapasDocumentos", _pedidoEtapaDocumento)
                                    .then(
                                        response6 => {
                                            // get body data
                                            this.PedidoDocumentos = response6.body;
                                            this.$redirect_reload("/meusDocumentos/" + parseInt(this.PedidoId, 10));
                                        },
                                        async response6 => {
                                            let recebe2 = response6.body;
                                            this.$mensagem_normal(recebe2);
                                        }
                                    );

                            },
                            (response3) => {
                                // error callback
                                this.$mensagem_erro(response3.body);
                            }
                        );
                    }
                }
            }
        },
        EnviaAnalise() {
            this.$http
                .get(this.$apiUrl + "/PedidoEtapasDocumentos/pedido/" + this.PedidoId)
                .then((res) => res.json())
                .then((retornoAPI) => {
                    this.Arquivos = retornoAPI;
                    
                    let _ArquivosEtapa = this.Arquivos.filter(x => x.analisar == false && x.aprovado == false);
                       _ArquivosEtapa.forEach(element => {
                        
                        let _pedidoEtapaDocumento = {}
                                _pedidoEtapaDocumento = {
                                    PedidoEtapasDocumentosId : element.pedidoEtapasDocumentosId,
                                    UrlDoc: element.urlDoc,
                                    NomeDocumento: element.nomeDocumento,
                                    NomeEtapa: element.nomeEtapa,
                                    DataInicio: element.dataInicio,
                                    DataCarga: element.dataCarga,
                                    Analisar: true,
                                    Aprovado: false,
                                    Pedido: { PedidoId: parseInt(this.PedidoId, 10) },
                                }

                                this.$http
                                    .put(this.$apiUrl + "/PedidoEtapasDocumentos/" + element.pedidoEtapasDocumentosId, _pedidoEtapaDocumento)
                                    .then(
                                        response6 => {
                                            // get body data
                                            this.PedidoDocumentos = response6.body;
                                        },
                                        async response6 => {
                                            let recebe2 = response6.body;
                                            this.$mensagem_normal(recebe2);
                                        }
                                    );
                    });
                    this.EnviarEmail();
                });
        },
        ExcluirDocumento(value){
            this.$http
                    .delete(this.$apiUrl + "/PedidoEtapasDocumentos/" + value)
                    .then(
                        () => {
                            // get body data
                            this.$redirect_reload("/meusDocumentos/" + parseInt(this.PedidoId, 10));
                        },
                        (response) => {
                            // error callback
                            console.log(response);
                        }
                    );
        },
        EnviarEmail() {
            this.$http
                .get(this.$apiUrl + "/institucional/telas/contato")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Retorno = tiu2;
                    if (this.Retorno != null) {
                        this.contatoEmail = this.Retorno[0].referencia;

                        let tmp = { ToEmail: this.contatoEmail, Subject: 'Documento para Aprovação. Pedido: ' + this.PedidoId, Body: " Existem Documentos para aprovação. Cliente: " + this.Pedido.cliente.nomeEmpresa };
                        this.$http
                            .post(
                                this.$apiUrl + "/contato/enviamail/" + 'TropaSul', tmp
                            )
                            .then(
                                (response) => {
                                    if (response.ok) {
                                        //this.$mensagem_normal("E-mail enviado com sucesso");
                                        this.$redirect_reload("/meusDocumentos/" + parseInt(this.PedidoId, 10)); 
                                    }
                                },
                                (error) => {
                                    console.log(error);
                                    this.Resposta = error.bodyText;
                                }
                            );
                    }
                });
        }
    },
    created() {

        let usuarioTmp = JSON.parse(this.$cookie.get(this.$UsuarioCookie));
        if (usuarioTmp != null) {
            this.ClienteId = parseInt(usuarioTmp.usuarioId, 10)
        }

        this.$http
            .get(this.$apiUrl + "/institucional/telas/etapas")
            .then((res) => res.json())
            .then((retornoAPI2) => {
                this.Etapas = retornoAPI2.sort(function (a, b) {
                    if (a.ordem > b.ordem) {
                        return 1;
                    }
                    if (a.ordem < b.ordem) {
                        return -1;
                    }
                    return 0;
                });

                this.$http
                    .get(this.$apiUrl + "/pedido/" + this.PedidoId)
                    .then((res) => res.json())
                    .then((retornoAPI3) => {
                        this.Pedido = retornoAPI3

                        console.log('this.Pedido')
                        console.log(this.Pedido)

                        this.$http
                            .get(this.$apiUrl + "/PedidoEtapasDocumentos/pedido/" + this.PedidoId)
                            .then((res) => res.json())
                            .then((retornoAPI) => {
                                this.Arquivos = retornoAPI;
                                if (this.Arquivos == null || this.Arquivos == "") {
                                    //primeira etapa
                                    this.IdEtapa = 1;
                                    this.NomeEtapa = this.Etapas[0].nome;
                                    this.TextoEtapa1 = this.Etapas[0].resumo1;
                                    this.DataInicioEtapa = this.Pedido.dataPedido;
                                    this.PrazoEtapa = moment(this.Pedido.dataPedido).add(this.Etapas[0].campoExtra1, 'days').format('DD/MM/YYYY');
                                }
                                else {
                                    console.log('Entrei else')
                                    let _Etapa = this.Arquivos.filter(x => x.analisar == false && x.aprovado == false);
                                    console.log('busquei Etapa analisar e aprovado false')
                                    console.log(_Etapa)
                                    if (_Etapa!= "")
                                    {
                                        console.log('tem valor etapa')
                                        let _mesmaEtapa = this.Etapas.filter(x => x.nome == _Etapa[0].nomeEtapa);
                                        console.log('Busco dados da etapa atual')
                                        console.log(_mesmaEtapa)
                                        this.IdEtapa = _mesmaEtapa[0].ordem;
                                        this.NomeEtapa = _mesmaEtapa[0].nome;
                                        this.TextoEtapa1 = _mesmaEtapa[0].resumo1;
                                        if (_mesmaEtapa[0].ordem == 1)
                                        {
                                            this.DataInicioEtapa = this.Pedido.dataPedido;
                                            this.PrazoEtapa = moment(this.Pedido.dataPedido).add(_mesmaEtapa[0].campoExtra1, 'days').format('DD/MM/YYYY');
                                        }
                                        else
                                        {
                                            console.log('entrei mesma etapa diferente de ordem 1')
                                            console.log(_mesmaEtapa[0].ordem)

                                            let _ArquivosEtapa = this.Arquivos.reduce(function(prev, current) { 
                                                return prev.dataCarga > current.dataCarga ? prev : current; 
                                            });
                                            this.DataInicioEtapa = _ArquivosEtapa.dataInicio;
                                            this.PrazoEtapa = moment(_ArquivosEtapa.dataInicio).add(_mesmaEtapa[0].campoExtra1, 'days').format('DD/MM/YYYY');
                                         }
                                    }
                                    else
                                    {
                                        console.log('Não false em valor etapa')
                                        let _ArquivosEtapa = this.Arquivos.reduce(function(prev, current) { 
                                                return prev.dataCarga > current.dataCarga ? prev : current; 
                                            });
                                        console.log('Busco ultimo item')
                                        console.log(_ArquivosEtapa)
                                        let _AtualEtapa = this.Etapas.filter(x => x.nome == _ArquivosEtapa.nomeEtapa);
                                        console.log('pelo nome pego etapa atual')   
                                        console.log(_AtualEtapa)   
                                        let _NovaEtapa;    
                                        if ((_AtualEtapa[0].ordem == 1 && _ArquivosEtapa.aprovado == true) || (_AtualEtapa[0].ordem == 3 && _ArquivosEtapa.aprovado == true) || (_AtualEtapa[0].ordem == 5 && _ArquivosEtapa.aprovado == true)){
                                            _NovaEtapa = this.Etapas.filter(x => x.ordem == (_AtualEtapa[0].ordem +2));
                                        }else{
                                            _NovaEtapa = this.Etapas.filter(x => x.ordem == (_AtualEtapa[0].ordem +1));
                                        }                            
                                        console.log('pela etapa atual pego a proxima')  
                                        console.log(_NovaEtapa)
                                        this.IdEtapa = _NovaEtapa[0].ordem;
                                        this.NomeEtapa = _NovaEtapa[0].nome;
                                        this.TextoEtapa1 = _NovaEtapa[0].resumo1;
                                        this.DataInicioEtapa = _ArquivosEtapa.dataCarga;
                                        this.PrazoEtapa = moment(_ArquivosEtapa.dataCarga).add(_NovaEtapa[0].campoExtra1, 'days').format('DD/MM/YYYY');

                                    }                              
                                }
                            });

                    });

            });
    }
}

</script>

<style scoped>
@import '/css_cart/carrinho.css';
@import '/css_cart/minha_conta.css';
@import '/css/modais.css';
</style>