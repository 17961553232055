<template>

<section v-if="Depoimentos!= ''" class="ftco-section testimony-section bg-light">
  <div class="container-xl">
    <div class="row justify-content-center pb-4">
      <div class="col-md-7 text-center heading-section">
        <span class="subheading">Depoimentos</span>
        <h2 class="mb-3">Porque escolher a TropaSul</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <carousel class="carousel-testimony" :autoplay="true"
          :responsive="{0:{items:3,nav:false},600:{items:3,nav:false}}">
          <div v-for="itemDep in Depoimentos" :key="itemDep.instuticionalId" class="item">
            <div class="testimony-wrap">
              <div class="icon d-flex align-items-center justify-content-center">
                <span class="fa fa-quote-left"></span>
              </div>
              <div class="text mt-3">
                <p class="mb-4 msg" v-html="itemDep.resumo1"></p>
                <div class="d-flex align-items-center">
                  <div class="ps-3 tx">
                    <p class="name">{{itemDep.nome}}</p>
                    <span class="position">{{itemDep.referencia}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </carousel>
      </div>
    </div>
  </div>
</section>
</template>

<script>
import carousel from 'vue-owl-carousel'

export default {
  components: { 
    carousel 
  },
   data() {
            return {
                Depoimentos: [],
            }
        },
   created() {

            this.$http
                .get(this.$apiUrl + "/institucional/telas/depoimentos")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Depoimentos = tiu2;
                });

               
        },
}

</script>

<style scoped>

  .text_depo {
    color: #828282;
    font-weight: 400;
    font-style: italic;
  }

  .autor_depo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 40px auto;
    max-width: 220px;
  }

  .img-depo {
    width: 70px !important;
    height: 70px;
    border-radius: 50%;
  }

</style>