<template>
<div>
    <!-- Breadcrumb Begin -->
    <section class="hero-wrap hero-wrap-2" style="background-image: url('/images/bg_1.jpg');height: 250px;">
            <div class="overlay"></div>
            <div class="container">
                <div class="row no-gutters slider-text justify-content-center" style="padding-top: 100px;">
                    <div class="col-md-9 mb-5 text-center">

                        <h1 class="mb-0 bread">Produto</h1>
                    </div>
                </div>
            </div>
        </section>
        <!-- Breadcrumb End -->

    <section class="ftco-section">
        
        <div class="container">
            <div class="row">

                <!-- Selected Image -->
                <div class="col-lg-5 order-lg-2 order-1">
                    <div class="text-center py-5" style="border:0px solid #cccccc;">
                       <lingallery :iid.sync="currentId" :addons="{ enableLargeView: false, accentColor: '#AA6144' }"
                            :width="450" :height="560" :items="ImgProduto" /> 
                    </div>
                </div>

                <!-- Description -->
                <div class="col-lg-6 offset-lg-1 col-12 order-2 mt-4 mt-lg-0">
                    <div class="text">
                        <span>{{ nomeSegmento }}</span>
                        <div class="">
                            <h4>Referência: {{ Produto.referencia }} - {{ Produto.nomeProduto }}</h4>
                        </div>

                        <span class="text">
                            <p v-html="Produto.resuno"></p>
                        </span>
                        <div class="order_info d-flex flex-row mt-4">
                            <form action="#">
                                <div class="clearfix" style="z-index:0;display:inline-flex;">
                                    <!-- Product Quantity -->
                                    <div class="product_quantity clearfix" style="padding-right: 30px;">
                                        <span>Quantidade: </span>
                                        <input v-model="Qtde" class="form-control" autofocus type="number" min="1"
                                            style="width:100px;">
                                    </div>
                                    <div class="product_quantity clearfix">
                                        <span>Valor: </span>
                                        <!--<small  class="d-block product_type color-light">De: R$ {{  parseFloat(Produto.valorDe,10).toFixed(2).toString().replace('.',',') }} por</small>-->
                                        <h4 v-if="(Produto.valorAte > 0)">R$ {{parseFloat(subtotal).toFixed(2).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</h4>
                                        <h4 v-else>Valor sob consulta</h4>
                                    </div>
                                </div>
                                 <div v-if="Produto.qtdeMinima > 0" class="button_container">
                                    <a href="#" v-if="Produto.indisponivel != true" @click="CarregaFinal()"
                                        class="btn btn-lg btn-success w-100 mt-4">Adicionar no Carrinho
                                        <span><i class="fa fa-shopping-cart" style="font-size: 20px;color: #FFFFFF;"></i>
                                        </span>
                                    </a>
                                    <a v-if="Produto.indisponivel == true" class="texto-destaque"
                                        style="margin-top: 5px;">Indispon&iacute;vel</a>
                                </div> 
                                <div v-else class="button_container">
                                    <a :href="linkwhats" target="_blank" class="btn btn-lg btn-success w-100 mt-4">Venda Suspensa, consulte 
                                        <span><i class="fa fa-whatsapp" style="font-size: 20px;color: #FFFFFF;"></i>
                                        </span>
                                    </a>
                                </div>

                                <!-- Formas de Pagamento -->
                                <div class="box-payment mt-5 mt-lg-2">
                                    <img class="img-fluid mt-4" src="/images/cartoes2.png"
                                        alt="Bandeiras dos cartões aceitos" style="margin-top:30px;">
                                </div>
                                <div class="mb-3">
                                    <strong>Simulação de Parcelamento (sem frete)</strong>
                                    <table style="font-size: 12px;padding-top: 10px;">
                                        <tr>
                                            <td>
                                                {{ "1x de R$" +  parseFloat(this.subtotal).toFixed(2).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " - Sem Juros" }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="background-color: #e9dcdc;">
                                                {{ "2x de R$ " + (((100 * parseFloat(this.subtotal)) / (100-5.12)) / 2).toFixed(2).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' Total R$ ' + ((100 * parseFloat(this.subtotal)) / (100-5.12)).toFixed(2).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{ "3x de R$ " + (((100 * parseFloat(this.subtotal)) / (100-5.54)) /3).toFixed(2).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' Total R$ ' + ((100 * parseFloat(this.subtotal)) / (100-5.54)).toFixed(2).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="background-color: #e9dcdc;">
                                                {{ "4x de R$ " + (((100 * parseFloat(this.subtotal)) / (100-5.96)) /4).toFixed(2).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' Total R$ ' + ((100 * parseFloat(this.subtotal)) / (100-5.96)).toFixed(2).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{ "5x de R$ " + (((100 * parseFloat(this.subtotal)) / (100-6.38)) /5).toFixed(2).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' Total R$ ' + ((100 * parseFloat(this.subtotal)) / (100-6.38)).toFixed(2).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="background-color: #e9dcdc;">
                                                {{ "6x de R$ " + (((100 * parseFloat(this.subtotal)) / (100-6.8)) /6).toFixed(2).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' Total R$ ' + ((100 * parseFloat(this.subtotal)) / (100-6.8)).toFixed(2).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{ "7x de R$ " + (((100 * parseFloat(this.subtotal)) / (100-7.72)) /7).toFixed(2).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' Total R$ ' + ((100 * parseFloat(this.subtotal)) / (100-7.72)).toFixed(2).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")  }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="background-color: #e9dcdc;">
                                                {{ "8x de R$ " + (((100 * parseFloat(this.subtotal)) / (100-8.14)) /8).toFixed(2).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' Total R$ ' + ((100 * parseFloat(this.subtotal)) / (100-8.14)).toFixed(2).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{ "9x de R$ " + (((100 * parseFloat(this.subtotal)) / (100-8.56)) /9).toFixed(2).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' Total R$ ' + ((100 * parseFloat(this.subtotal)) / (100-8.56)).toFixed(2).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="background-color: #e9dcdc;">
                                                {{  "10x de R$ " + (((100 * parseFloat(this.subtotal)) / (100-8.98)) /10).toFixed(2).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' Total R$ ' + ((100 * parseFloat(this.subtotal)) / (100-8.98)).toFixed(2).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")  }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{  "11x de R$ " + (((100 * parseFloat(this.subtotal)) / (100-9.4)) /11).toFixed(2).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' Total R$ ' + ((100 * parseFloat(this.subtotal)) / (100-9.4)).toFixed(2).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")  }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="background-color: #e9dcdc;">
                                                {{ "12x de R$ " + (((100 * parseFloat(this.subtotal)) / (100-9.82)) /12).toFixed(2).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' Total R$ ' + ((100 * parseFloat(this.subtotal)) / (100-9.82)).toFixed(2).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")  }}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <!-- Calcular frete -->
                                <div v-if="Produto.qtdeMinima > 0" class="input-group">
                                    <input type="text" class="form-control input-frete" v-model="numCep"
                                        placeholder="Informe o CEP" aria-label="Informe o CEP"
                                        aria-describedby="button-addon2" style="font-size: 12px;height: 38px;">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-success btn-frete"
                                            @click.prevent="CalculaFrete()"><i class="fas fa-shipping-fast mr-1"></i>Ver
                                            Frete</button>
                                    </div>
                                </div>
                                <div class="col-md-12 mt-4 mt-md-0 vHorarios" style="font-size: 14px;">
                                    <label v-if="ListaEntregaFrete != ''"
                                        style="font-size:15px;font-weight:400;padding-top:10px;">Visualizar tipos da
                                        Entrega<span style="color:red;">*</span></label>
                                    <div v-for="(listaFrete, index) in ListaEntregaFrete" :key="index"
                                        class="form-check">
                                        <span> - {{ listaFrete.nome }} <span v-if="listaFrete.valor != 0">- R$ {{
                                        parseFloat(listaFrete.valor).toFixed(2).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                        }}
                                            </span> <span v-if="listaFrete.nome.indexOf('RETIRE') == -1"> Prazo: {{
                                            listaFrete.prazo
                                            }} {{
                                                listaFrete.prazo == 1 ? 'dia útil' : 'dias úteis'
                                                }}</span> </span>
                                    </div>
                                </div>
                                <p style="color:green;font-size:20px;padding-top: 10px;">{{ MostraCompraminia }}</p>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="description th__mb-100 pt-5">
            <div class="container">
                <div class="row">
                    <div class="col-lg-2 col-12" style="display:inline-flex;padding-right:10px;">
                        <h6 class="text-black"><a href="" style="color:#000000;">Especificações</a></h6>
                    </div>
                    <hr>
                    <div class="col">
                        <br />
                        <p v-html="Produto.descricao"></p>
                    </div>
                </div>
            </div>
        
        
        </div>
    </section>
</div>
</template>

<script>

import { VMoney } from 'v-money'
import moment from 'moment';
import Lingallery from 'lingallery';


export default {
    metaInfo: {
        bodyScript: [
            { src: '' }
        ],
    },
    components: {
        Lingallery,
    },
    data() {
        return {
            Pagina: this.$route.params.pagina,
            vModal: this.$route.params.modal,
            ProdutoId: this.$route.params.id,
            segmentoProdNivel1Id: this.$route.params.segmento,
            segmentoProdNivel2Id: '',
            nomeSegmento: '',
            Produto: {},
            ProdutosIncluso: [],
            ProdutoIncluso: {},
            money: {
                decimal: ',',
                thousands: '.',
                precision: 2,
                masked: true
            },
            ImgProduto: [],
            currentId: null,
            ProdutosAvulso: [],
            ProdutoAvulso: {},
            totalavulsos: 0,
            totalfoto: 0,
            DadosCompra: {
                produtos: [],
                mensagem: [],
                agenda: [],
                foto: [],
                avulsos: [],
                baloes: [],
                informacao: [],
                pagamento: [],
                cupom: [],
            },
            ProdutosInfoAdicional: [],
            Produtoinfo: {},
            Texto: '',
            ListaEntregaFrete: [],
            vValor: 0,
            EntregaFreteId: 0,
            showModal_addProd: false,
            ValorTotal: 0,
            Qtde: 1,
            pais: {},
            listaFrete: {},
            Frete: '',
            numCep: '',
            CepOrigem: '',
            ligacaoProduto: [],
            ListaEtiquetaImg: [],
            mostraPremio: true,
            UrlimgBannerGeral: '',
            liberaValores: false,
            ClientesGeral: [],
            Clientes: [],
            ClientesTabelaPreco: [],
            TabelaPreco: [],
            MostraCompraminia: '',
            Etiquetas: [],
        }
    },
    directives: { money: VMoney },
    methods: {
        MudaTexto(value) {
            this.Texto = value.descricaoInfo;
        },
        currentDateTime(vdate) {
            return moment(vdate).format('LL');
        },
        CarregaFinal() {
            if (this.Pagina != null) {
                this.$redirect("/" + this.Pagina);
            } else {
                if (parseInt(this.Produto.qtdeMinima, 10) < parseInt(this.Qtde, 10)) {
                    this.$mensagem_normal("Quantidade indisponível.");
                }
                else {
                    this.ArmazenaDados('produtos', null)
                    this.$redirect_reload("/carrinho");
                }
            }
        },
        ArmazenaDados(item, value) {

            let varray = value;
            //console.log("array " + varray)

            if (item == 'produtos') {
                let dadosArmazenados = localStorage.getItem(this.$UsuarioDados);
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    dadosArmazenados.produtos.push({ produtoId: parseInt(this.ProdutoId, 10), qtde: parseInt(this.Qtde, 10), produto: this.Produto });
                    localStorage.setItem(this.$UsuarioDados, JSON.stringify(dadosArmazenados))

                }
            }

            if (item == 'avulsos') {
                let dadosArmazenados = localStorage.getItem(this.$UsuarioDados);
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);

                    let vrecebe = dadosArmazenados.avulsos.filter((x) => {
                        return x.produtoAvulsoId == varray.itemAvulso.itemAvulsoId;
                    });

                    let vrecebe2 = dadosArmazenados.avulsos.filter((x) => {
                        return x.produtoAvulsoId == 0;
                    });

                    if (vrecebe2 != '') {
                        dadosArmazenados.avulsos = [];
                    }

                    if (vrecebe == '') {
                        dadosArmazenados.avulsos.push({ produtoAvulsoId: varray.itemAvulso.itemAvulsoId, avulso: varray.itemAvulso });
                        localStorage.setItem(this.$UsuarioDados, JSON.stringify(dadosArmazenados))
                    }
                }
            }
        },
        totalizaavulsos() {
            let dadosArmazenados = localStorage.getItem(this.$UsuarioDados);
            if (dadosArmazenados) {
                dadosArmazenados = JSON.parse(dadosArmazenados);
                if (dadosArmazenados.avulsos != '') {
                    if (dadosArmazenados.avulsos[0].avulso == "Sem Avulsos") {
                        return 0;
                    }
                    else {
                        let recebe = dadosArmazenados.avulsos.reduce((sum, item) => sum + item.avulso.valorDe, 0);
                        return recebe;
                    }
                }
                else {
                    return 0;
                }

            }
            else {
                return 0;
            }
        },
        CalculaFrete() {
            if (this.numCep == '') {
                this.$mensagem_normal("Por favor preencha o CEP de entrega");
            }
            else {

                this.$http
                    .get(this.$apiUrl + "/entregacorreio")
                    .then((res2) => res2.json())
                    .then((tiu2) => {
                        this.CepOrigem = tiu2[0].cep;

                        let InformacaoFrete = {
                            Produtos: [],
                            ProdutosAvulsos: [],
                            CepOrigem: this.CepOrigem,
                            CepDestino: this.numCep,
                            Correios: false,
                            Entregas: true
                        }
                        let _produto = this.Produto;
                        _produto.qtdeMinima = parseInt(this.Qtde, 10);
                        //console.log(_produto)

                        InformacaoFrete.Produtos.push(_produto);

                        //this.ListaAvulsos.forEach((itempa) => {
                        //    InformacaoFrete.ProdutosAvulsos.push(itempa.itemAvulso);
                        //});

                        console.log(InformacaoFrete)

                        this.$http
                            .post(this.$apiUrl + "/EntregaFrete/Calcular", InformacaoFrete)
                            .then(response => {

                                this.ListaEntregaFrete = response.body
                                //console.log("aqui");
                                console.log(this.ListaEntregaFrete)
                                let vrecebe = []
                                this.ListaEntregaFrete.forEach((itemex) => {
                                    //aqui valida compra minima
                                    if (itemex.compraMinima > 0) {
                                        this.MostraCompraminia = "Nas Compras acima de R$ " + parseFloat(itemex.compraMinima).toFixed(2).toString().replace('.', ',') + ", Você ganha frete Grátis!";
                                    }

                                    if (this.subtotal > itemex.compraMinima) {
                                        vrecebe.push(itemex);
                                    }
                                });

                                if (vrecebe != '') {
                                    let _filtraGratis = vrecebe;
                                    let vrecebe2 = []
                                    _filtraGratis.forEach((itemex2) => {
                                        if (itemex2.nome.indexOf("Frete") != -1 || itemex2.nome.indexOf("Miguel") != -1) {
                                            vrecebe2.push(itemex2);
                                        }
                                    });

                                    if (vrecebe2 != '') {
                                        this.ListaEntregaFrete = vrecebe2;
                                        this.MostraCompraminia = "";
                                    }
                                    else {
                                        this.ListaEntregaFrete = _filtraGratis;
                                    }
                                }
                                //console.log(this.ListaEntregaFrete);
                            },
                                error => {
                                    this.$mensagem_erro("CEP Inválido.")
                                    console.log(error)
                                });

                    });
            }

        },
    },
    created() {
        this.totalizaavulsos();

        //carrega informações do produto
        this.$http
            .get(this.$apiUrl + "/produto/" + parseInt(this.ProdutoId, 10))
            .then((res2) => res2.json())
            .then((tiu2) => {
                this.Produto = tiu2
                this.Produto.imagens.sort(function (a, b) {
                    return a.ordem - b.ordem;
                }).forEach(element => {
                    if (element.urlImg != '') {
                        this.ImgProduto.push({ src: this.$Url + element.urlImg, thumbnail: this.$Url + element.urlImg })
                    }
                }
                );
                  console.log('this.Produto')
                  console.log(this.Produto)
            });

        //carrega informações de todos os produtos
        this.$http
            .get(this.$apiUrl + "/produtotextogeral")
            .then((res2) => res2.json())
            .then((tiu2) => {
                if (tiu2 != null) {
                    this.UrlimgBannerGeral = tiu2[0].urlImg;
                    this.linkwhats = tiu2[0].texto1;
                }
            });

        // cria localstorage para armazenar informações da compra
        let dadosArmazenados = localStorage.getItem(this.$UsuarioDados);
        if (!dadosArmazenados) {
            dadosArmazenados = this.DadosCompra;
            localStorage.setItem(this.$UsuarioDados, JSON.stringify(dadosArmazenados))
        }

    },
    mounted() {
    },
    computed: {
        subtotal: function () {
            return this.totalavulsos + (this.Produto.valorAte * parseInt(this.Qtde, 10));
        },
    }
}
</script>

<style scoped>
input {
    font-size: 20px;
    padding: 6px;
}

input[type=number]::-webkit-inner-spin-button {
    all: unset;
    min-width: 21px;
    min-height: 45px;
    margin: 17px;
    padding: 0px;
    background-image:
        linear-gradient(to top, transparent 0px, transparent 16px, #fff 16px, #fff 26px, transparent 26px, transparent 35px, #000 35px, #000 36px, transparent 36px, transparent 40px),
        linear-gradient(to right, transparent 0px, transparent 10px, #000 10px, #000 11px, transparent 11px, transparent 21px);
    transform: rotate(90deg) scale(0.8, 0.9);
    cursor: pointer;
}

.lingallery_thumbnails_content_elem img {
    border-color: #AA6144 !important;
}

.lingalleryContainer .lingallery .lingallery_spinner {
    position: absolute;
    left: 50%;
    top: calc(50% - 32px);
    display: none !important;
}
</style>