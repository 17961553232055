import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/components/site/views/Home.vue'
import Produtos from '@/components/site/views/PageProdutos.vue'
import ProdutosBusca from '@/components/site/views/PageProdutosBusca.vue'
import ProdutoDetalhe from '@/components/site/views/ProdutoDetalhe.vue'
import Sobre from '@/components/site/views/PageSobre.vue'
// Carrinho
import Carrinho from '@/components/site/views/Carrinho.vue'
import Login from '@/components/site/views/Login.vue'
import NovaContaLogin from '@/components/site/views/NovaContaLogin.vue'
import NovaContaEndereco from '@/components/site/views/NovaContaEndereco.vue'
import NovaContaPagamento from '@/components/site/views/NovaContaPagamento.vue'
import NovaContaConfirmacao from '@/components/site/views/NovaContaConfirmacao.vue'
import ComprovantePgto from '@/components/site/views/ComprovantePgto.vue'
import PedidoRealizado from '@/components/site/views/PedidoRealizado.vue'
import MeusDados from '@/components/site/views/PageMeusDados.vue'
import MeusPedidos from '@/components/site/views/PageMeusPedidos.vue'
import MeusPedidosDetalhe from '@/components/site/views/PageMeusPedidosDetalhe.vue'
import MeusArquivos from '@/components/site/views/PageMeusArquivos.vue'
import MeusDocumentos from '@/components/site/views/PageMeusDocumentos.vue'
import MeusVideos from '@/components/site/views/PageMeusVideos.vue'
import meusGaleriaFotos from '@/components/site/views/PagemeusGaleriaFotos.vue'
import InformacoesGerais from '@/components/site/views/InformacoesGerais.vue'
import Contato from '@/components/site/views/PageContato.vue'
import Blog from '@/components/site/views/PageBlog.vue'
import BlogDetalhe from '@/components/site/views/PageBlogDetalhe.vue'

Vue.use(VueRouter)


export default new VueRouter({
    mode: 'history',
    linkActiveClass: 'active',
    scrollBehavior(to, from, savedPosition) {
        return new Promise((resolve) => {
            setTimeout(() => {
                if (savedPosition) {
                    return resolve(savedPosition)
                }
                if (to.hash) {
                    return resolve({
                        selector: to.hash,
                        offset: { x: 0, y: 0 }
                    })
                }
                return resolve({
                    x: 0, y: 0,
                    behavior: 'smooth'
                })
            }, 700)
        })
    },
    routes: [
        { path: '/', component: Home },
        { path: '/produtos', component: Produtos},
        { path: '/produtosbusca/:texto', component: ProdutosBusca },
        { path: '/produtos/:id/:idcategoria/:segmento/:nome', component: Produtos},
        { path: '/produtos/:id/:idcategoria/:segmento', component: Produtos},
        { path: '/produtoDetalhe/:id/:segmento/:nome', component: ProdutoDetalhe },
        { path: '/carrinho', component: Carrinho },
        { path: '/login', component: Login },
        { path: '/novaContaLogin', component: NovaContaLogin },
        { path: '/novaContaEndereco', component: NovaContaEndereco },
        { path: '/novaContaPagamento', component: NovaContaPagamento },
        { path: '/novaContaConfirmacao', component: NovaContaConfirmacao },
        { path: '/comprovantePgto', component: ComprovantePgto },
        { path: '/pedidoRealizado', component: PedidoRealizado },
        { path: '/meusDados', component: MeusDados },
        { path: '/meusPedidos', component: MeusPedidos },
        { path: '/meusPedidosDetalhe', component: MeusPedidosDetalhe },
        { path: '/meusPedidosDetalhe/:id', component: MeusPedidosDetalhe },
        { path: '/meusArquivos', component: MeusArquivos },
        { path: '/meusDocumentos/:id', component: MeusDocumentos },
        { path: '/meusVideos', component: MeusVideos },
        { path: '/meusGaleriaFotos', component: meusGaleriaFotos },
        { path: '/InformacoesGerais/:id/:nome', component: InformacoesGerais },
        { path: '/contato', component: Contato },
        { path: '/blog', component: Blog },
        { path: '/blogDetalhe/:id/:nome', component: BlogDetalhe },
        { path: '/sobre', component: Sobre },
    ]
})