<template>

    <section class="ftco-section img ftco-no-pb" :style="{backgroundImage: 'url(' + $Url + fundotela + ')'}">
        <div class="container">
            <div class="row mb-5">
                <div class="col-md-7 heading-section heading-section-white">
                    <span class="subheading">{{Titulopeq}}</span>
                    <h2 v-html="Texto"></h2>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="ftco-search d-flex justify-content-center">
                        <div class="row">
                            <div class="col-md-12 nav-link-wrap d-flex">
                                <div class="nav nav-pills text-center" id="v-pills-tab" role="tablist"
                                    aria-orientation="vertical">
                                    <a class="nav-link active" id="v-pills-1-tab" data-toggle="pill" href="#v-pills-1"
                                        role="tab" aria-controls="v-pills-1" aria-selected="true">Contato</a>
                                </div>
                            </div>
                            <div class="col-md-12 tab-wrap">

                                <div class="tab-content" id="v-pills-tabContent">

                                    <div class="tab-pane fade show active" id="v-pills-1" role="tabpanel"
                                        aria-labelledby="v-pills-nextgen-tab">
                                        <form action="#" class="search-property-1">
                                            <div class="row g-0">
                                                <div class="col-md-12 d-flex">
                                                    <div class="form-group mb-3">
                                                        <label for="#">Seu contato</label>
                                                        <div class="form-field d-flex">
                                                            <input type="text" v-model="Nome" class="form-control me-2"
                                                                placeholder="Nome">
                                                            <input type="text" class="form-control ms-2"
                                                                v-model="Telefone" placeholder="Telefone">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 d-flex">
                                                    <div class="form-group mb-3">
                                                        <label for="#">E-mail</label>
                                                        <div class="form-field">
                                                            <input type="text" class="form-control" v-model="Email"
                                                                placeholder="E-mail">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 d-flex">
                                                    <div class="form-group d-flex w-100">
                                                        <div class="form-field w-100 align-items-center d-flex">
                                                            <input type="submit" value="Enviar" @click.prevent="Enviar()"
                                                                class="align-self-stretch form-control btn btn-primary me-2">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 d-flex" style="text-align:center;">
                                                    <span style="color:#AA6144;">
                                                        {{ Resposta }}
                                                    </span>
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>

<script>
//import moment from 'moment'
export default {

    components: {
    },
    data() {
        return {
            Emailnews: '',
            news: [],
            Institucional: {},
            Tela: {},
            Nome: '',
            Email: '',
            Telefone: '',
            Mensagem: '',
            Resposta: '',
            EmailRecebe: '',
            Titulopeq: '',
            Texto: '',
            fundotela:'',
            Retorno:'',
        }
    },
    methods: {
        Limpar() {
            this.$redirect_reload("/contato");
        },
        Enviar() {
            this.Resposta = "";
            if (this.Email == "") {
                this.Resposta = "Por favor preencha o e-mail";
            } else if (this.Telefone == "") {
                this.Resposta = "Por favor preencha Telefone";
            } else {
                let tmp = { ToEmail: this.EmailRecebe, Subject: 'Contato do Site', Body: "Nome: " + this.Nome + " | Telefone: " + this.Telefone + " | E-mail: " + this.Email };
                this.$http
                    .post(
                        this.$apiUrl + "/contato/enviamail/" + 'TropaSul', tmp
                    )
                    .then(
                        (response) => {
                            if (response.ok) {
                                this.Resposta = "E-mail enviado com sucesso";
                                this.Nome = '';
                                this.Telefone = '';
                                this.Email = '';
                                this.Mensagem = '';

                            }
                        },
                        (error) => {
                            console.log(error);
                            this.Resposta = error.bodyText;
                        }
                    );
            }
        }
    },
    created() {

        this.$http
            .get(this.$apiUrl + "/institucional/telas/formulario")
            .then((res2) => res2.json())
            .then((tiu2) => {
                this.Retorno = tiu2;

                if (this.Retorno != null) {
                    this.Titulopeq = this.Retorno[0].nome;
                    this.EmailRecebe = this.Retorno[0].referencia;
                    this.Texto = this.Retorno[0].resumo1;
                    if (this.Retorno[0].institucionalImg != '') {
                        this.fundotela = this.Retorno[0].institucionalImg[0].urlImagem
                    }
                    else {
                        this.fundotela = '';
                    }
                }

            });
    },
}
</script>
