<template>
    <div>
        <!-- Breadcrumb Begin -->
        <section class="hero-wrap hero-wrap-2" style="background-image: url('/images/bg_1.jpg'); height: 250px;">
            <div class="overlay"></div>
            <div class="container">
                <div class="row no-gutters slider-text justify-content-center" style="padding-top: 100px;">
                    <div class="col-md-9 mb-5 text-center">

                        <h1 class="mb-0 bread">Produtos</h1>
                    </div>
                </div>
            </div>
        </section>
        <!-- Breadcrumb End -->


        <section class="ftco-section">
            <div class="container">
                <div class="row g-lg-4">
                    <div class="col-lg-12">
                        <span v-html="TextoGeral"></span>
                       <v-select v-model="Ordemselected" @input="MudaOrdem" style="position:relative;z-index:99999999;float:right;width:200px;"
                        :options="Ordenar" :reduce="nome => nome.valor" single-line label="nome" class="style-chooser">
                      </v-select>
                    </div>
                    <div class="col-md-12" style="text-align: center;">
                        <a v-for="MenuSegmento in MenuSegmentos" :key="MenuSegmento.segmentoProdNivel1Id" href="#" @click.prevent="CarregaProdutos(MenuSegmento)" class="btn-custom btn btn-primary me-2 mb-2">{{ MenuSegmento.nomeSegmento }}</a>
                    </div>
                    <div class="col-md12" style="display: inline-flex;">
                        <h2>{{SegmentoClicado}}</h2>
                        <input v-model="searchQuery" placeholder="Pesquisar palavra chave..." style="margin-left: 20px;" />
                    </div>
                    <div class="col-md-4" v-for="produto in filteredProdutos" :key="produto.produtoId">
                        <a href="#" @click="$redirect_reload('/produtoDetalhe/' + produto.produtoId + '/' + segmentoProdNivel1Id + '/' + produto.nomeProduto.replaceAll(' ','-').replaceAll('/','').replaceAll('+','-'))">
                            <div class="classes-wrap img d-flex align-items-end"
                            :style="{ backgroundImage: 'url(' + $Url + produto.imagens[0].urlImg + ')', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', border: '1px solid #cccccc' }">
                                <div class="text">
                                    <h2 v-if="(produto.valorAte > 0)" style="color: #ffffff;">{{produto.nomeProduto }}<br />
                                        <span style="color: #ffffff;">R$ {{ parseFloat(produto.valorAte,10).toFixed(2).toString().replace('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</span>
                                    </h2>
                                    <h2 v-else style="color: #ffffff;">{{produto.nomeProduto }}<br />
                                        <span style="color: #ffffff;">Valor sob consulta</span>
                                    </h2>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="row mt-5" style="display: none;">
                    <div class="col text-center">
                        <div class="block-27">
                            <ul>
                                <li><a href="#">&lt;</a></li>
                                <li class="active"><span>1</span></li>
                                <li><a href="#">2</a></li>
                                <li><a href="#">3</a></li>
                                <li><a href="#">4</a></li>
                                <li><a href="#">5</a></li>
                                <li><a href="#">&gt;</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

</template>

<script>
//import vSelect from 'vue-select';
import { VMoney } from 'v-money'

export default {
    components: {
       // vSelect,
    },
    data() {
        return {
            searchQuery: '',
            segmentoProdNivel1Id: this.$route.params.id,
            MenuSegmentos: [],
            MenuSegmento: {},
            ProdutosGerais: [],
            Produtos: [],
            ProdutosFiltro: [],
            produto: {},
            ListaEtiquetaImg: [],
            NomeSegmentoFilho: '',
            Ordemselected: 'Ordenar por',
            Ordenar: [
                {
                    nome: "Maior Valor",
                    valor: 3
                },
                {
                    nome: "Menor Valor",
                    valor: 4
                }
            ],
            SegselectedNav: 'Escolha Segmento',
            SegMobile: [],
            parcelas: 0,
            parcelasTexto: '',
            TextoGeral: '',
            bg_imagem: '',
            money: {
                decimal: ',',
                thousands: '.',
                precision: 2,
                masked: true
            },
            fundotela: '',
            SegmentoClicado: '',
        }
    },
    directives: { money: VMoney },
    methods: {
        MudaOrdem() {
            if (this.Ordemselected == 3) {
                this.Produtos.sort(function (a, b) {
                    return a.valorAte - b.valorAte;
                }).reverse();
            } else if (this.Ordemselected == 4) {
                this.Produtos.sort(function (a, b) {
                    return a.valorAte - b.valorAte;
                });
            } else {
                this.Produtos.sort(function (a, b) {
                    if (a.produtoId > b.produtoId) {
                        return 1;
                    }
                    if (a.produtoId < b.produtoId) {
                        return -1;
                    }
                    return 0;
                }).reverse();
            }

        },
        MudaSegMobile() {
            this.Produtos = this.ProdutosGerais.filter(x => x.visivel && x.segmentos.some(y => y.segmentoProdNivel2.segmentoProdNivel2Id == parseInt(this.SegselectedNav.segmentoProdNivel2Id, 10))).sort(function (a, b) {
                return a.produtoid - b.produtoid
            });
            this.ProdutosFiltro = this.Produtos;
            this.AplicaFiltros();
            this.NomeSegmentoFilho = this.SegselectedNav.nomeSegmento;
            if (this.SegselectedNav.imagens != '') {
                this.bg_imagem = this.SegselectedNav.imagens[0].urlImagem;
            }
            else {
                this.bg_imagem = '';
            }
            this.MudaOrdem();
        },
        CarregaImagemUnica(value) {
            if (value != null) {
                value.sort(function (a, b) {
                    return a.ordem - b.ordem;
                });

                //value.sort(function (a, b) {
                //    if (a.ordem > b.ordem) {
                //        return 1;
                //    }
                //    if (a.ordem < b.ordem) {
                //        return -1;
                //    }
                //    return 0;
                //});
                return this.$Url + value[0].urlImg;
            }
            else {
                return this.$Url + "/imagens/indisponivel.jpg"
            }

        },
        CarregaProdutos(value) {
            this.searchQuery = '';
            this.SegmentoClicado = value.nomeSegmento;
            this.$http
                .get(this.$apiUrl + "/produto/segmentoprodnivel1/" + parseInt(value.segmentoProdNivel1Id, 10))
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.ProdutosGerais = tiu2
                    this.Produtos = this.ProdutosGerais
                    .filter(x => x.visivel && x.imagens != null && x.imagens != '')
                    .sort((a, b) => {
                        const nomeA = a.nomeProduto ? a.nomeProduto.toLowerCase() : '';
                        const nomeB = b.nomeProduto ? b.nomeProduto.toLowerCase() : '';
                        return nomeA.localeCompare(nomeB);
                    });
                    this.Produtos.forEach(produto => {
                        produto.imagens.sort((a, b) => a.ordem - b.ordem);
                    });
                    console.log('Produtos123')
                    console.log(this.Produtos)
                });
        },
    },
    created() {

        this.$http
            .get(this.$apiUrl + "/institucional/telas/empresa")
            .then((res2) => res2.json())
            .then((tiu2) => {
                this.Retorno = tiu2;

                if (this.Retorno != null) {
                    if (this.Retorno[0].institucionalImg != '') {
                        this.fundotela = this.Retorno[0].institucionalImg[0].urlImagem
                    }
                    else {
                        this.fundotela = '';
                    }
                }

            });

        this.$http
            .get(this.$apiUrl + "/SegmentoProdNivel1/")
            .then((res2) => res2.json())
            .then((tiu2) => {
                this.MenuSegmentos = tiu2.filter(x => x.visivel).sort(function (a, b) {
                    return a.ordem - b.ordem;
                });

                this.CarregaProdutos(this.MenuSegmentos[0])
            });


        this.$http
            .get(this.$apiUrl + "/produtotextogeral")
            .then((res2) => res2.json())
            .then((tiu2) => {
                this.parcelas = parseInt(tiu2[0].texto1, 10);
                this.parcelasTexto = tiu2[0].texto2;
                this.TextoGeral = tiu2[0].descricao1;
            });
    },
    mounted() {

    },
    computed: {
  filteredProdutos() {
    const normalizeString = (str) => {
      return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    };

    return this.Produtos.filter(produto => {
      const nomeProduto = produto.nomeProduto || ''; // Garante que nomeProduto seja uma string
      const normalizedProdutoNome = normalizeString(nomeProduto.toLowerCase());
      const normalizedSearchQuery = normalizeString(this.searchQuery.toLowerCase());

      return normalizedProdutoNome.includes(normalizedSearchQuery);
    });
  }
}
}

</script>

<style scoped>
@import '/css/produtos.css';

ul {
    padding-left: unset;
}

.form-control-sidebar {
    max-width: 80%;
}


.product_item {
    width: 100%;
    /*        background: #FFFFFF;
        box-shadow: 6px 4px 8px #ccc;*/
    padding-top: 130px;
    padding-bottom: 230px;
    transition: all .2s ease-in;
}

/*        .product_item:hover {
            box-shadow: 10px 10px 8px #ccc;
        }*/


.vfigure {
    position: absolute;
    float: left;
    top: 20px;
    height: 40px;
    z-index: 999;
    text-align: center;
}


/********************
    Responsive
    ********************/

/* Smartphones */
@media (max-width:767px) {
    .product_item {
        left: 0px;
    }

    .shop_sorting {
        float: initial;
    }

    .shop {
        background: #FFFFFF;
        padding-top: 20px;
        padding-bottom: 139px;
    }

    .vfigure {
        position: absolute;
        float: left;
        top: 65px;
        height: 40px;
        z-index: 999;
        text-align: center;
    }
}

/* Tudo, menos desktop */
@media (max-width:1199px) {
    .product_item {
        height: min-content;
        padding-top: 60px;
        padding-bottom: 80px;
    }

    .product_price {
        margin-top: 0px;
    }
}

.img-item {
    /*border-radius: 5px;*/
    /*   box-shadow: 1px 1px 10px #303030; */
    height: 260px;
}

.content {
    padding: 10px 5px 10px 5px;
}

/* Smartphones */
@media (max-width:767px) {
    .content {
        padding: 30px 10px 50px 10px;
    }
}
</style>



