<template>

    <section class="home" style="position:relative;">

        <section class="slider-hero">
            <div class="overlay"></div>
            <div class="hero-slider">
                <b-carousel id="carousel-1" v-model="slideItens" :interval="8000" fade controls indicators
                    img-width="1920" img-height="750" class="item work">
                    <!-- Slides with custom text -->
                    <b-carousel-slide v-for="slid in slider" :key="slid.institucionalId"
                        :img-src="$Url + slid.institucionalImg[0].urlImagem" class="img img2 d-flex align-items-center">
                        <div class="container-xl">
                            <div class="row">
                                <div class="col-md-12 col-lg-10">
                                    <div class="row">
                                        <div class="col-md-8 col-lg-7">
                                            <div class="text mt-md-5">
                                                <h2 v-html="slid.resumo1"></h2>
                                                <p class="mb-5" v-html="slid.resumo2"></p>
                                                <p><a v-if="slider.linkBtn1!=''" :href="slid.linkBtn1"
                                                        class="btn btn-primary px-4 py-3">{{slid.textoBtn1}} <span
                                                            class="ion ion-ios-arrow-round-forward"></span></a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-carousel-slide>
                </b-carousel>
            </div>
        </section>

        <section class="ftco-section">
            <div class="container">
                <div class="row justify-content-center pb-5">
                    <div class="col-md-7 text-center heading-section">
                        <span class="subheading">Produtos</span>
                        <h2 class="mb-3"><span>Destaques</span> do Mês</h2>
                    </div>
                </div>
                <div class="row g-lg-2">
                    <div v-for="prod in Produtos.slice(0,6)" :key="prod.produtoId" class="col-md-4">
                        <a href="#"  @click="$redirect_reload('/produtoDetalhe/' + prod.produtoId + '/0/' + prod.nomeProduto.replaceAll(' ','-').replaceAll('/','').replaceAll('+','-'))">
                            <div v-if="prod.imagens!=null && prod.imagens!=''" class="classes-wrap img d-flex align-items-end"
                                :style="{backgroundImage: 'url(' + $Url + prod.imagens[0].urlImg + ')', backgroundSize: 'contain'}">
                                <div class="text" style="min-height: 50px;">
                                    <h2 v-if="(prod.valorAte > 0)" style="color: #ffffff;">{{prod.nomeProduto}}<br />
                                        <span style="color: #ffffff;">R$ {{parseFloat(prod.valorAte,10).toFixed(2).toString().replace('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</span>
                                    </h2>
                                    <h2 v-else style="color: #ffffff;">{{prod.nomeProduto }}<br />
                                        <span style="color: #ffffff;">Valor sob consulta</span>
                                    </h2>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>

            </div>
            <div class="row mt-md-5">
                <div class="col text-center">
                    <a href="#" @click="$redirect('/produtos')" class="btn-custom">Veja mais opções <span
                            class="ion-ios-arrow-round-forward"></span></a>
                </div>
            </div>
        </section>

        <section v-if="ProdutosLancamentos!=''" class="ftco-section">
            <div class="container">
                <div class="row justify-content-center pb-5">
                    <div class="col-md-7 text-center heading-section">
                        <span class="subheading">Produtos</span>
                        <h2 class="mb-3"><span>Lançamentos</span></h2>
                    </div>
                </div>
                <div class="row g-lg-2">
                    <div v-for="prod in ProdutosLancamentos.slice(0,6)" :key="prod.produtoId" class="col-md-4">
                        <a href="#"  @click="$redirect_reload('/produtoDetalhe/' + prod.produtoId + '/0/' + prod.nomeProduto.replaceAll(' ','-').replaceAll('/','').replaceAll('+','-'))">
                            <div v-if="prod.imagens!=null && prod.imagens!=''" class="classes-wrap img d-flex align-items-end"
                                :style="{backgroundImage: 'url(' + $Url + prod.imagens[0].urlImg + ')', backgroundSize: 'contain'}">
                                <div class="text" style="min-height: 50px;">
                                    <h2 v-if="(prod.valorAte > 0)" style="color: #ffffff;">{{prod.nomeProduto}}<br />
                                        <span style="color: #ffffff;">R$ {{parseFloat(prod.valorAte,10).toFixed(2).toString().replace('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</span>
                                    </h2>
                                    <h2 v-else style="color: #ffffff;">{{prod.nomeProduto }}<br />
                                        <span style="color: #ffffff;">Valor sob consulta</span>
                                    </h2>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>

            </div>
        </section>

        <div class="pb-5"></div>
        <Newsletter />

        <Modal v-model="showModal" title="">
            <section class="section-modal modal-produtos">
                <div class="container">
                    <div class="row">
                        <div class="col text-center">
                            <h4 class="titulo-modal">
                                {{ tituloModal }}
                            </h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-12ccol-lg-12 my-4">
                            <span v-html="textoModal"></span>
                        </div>
                    </div>
                </div>
                <footer class="modal_footer">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-md-12 col-lg-12" style="text-align:right;">
                                <a class="btn btn-dark" @click="CienteModal()">
                                    {{ textoBtnmodal }}
                                </a>
                            </div>
                        </div>
                    </div>
                </footer>
            </section>
        </Modal>
    </section>

</template>

<script>
import Newsletter from '../shared/Newsletter.vue'

export default {
    components: {
        Newsletter,
    },
    data() {
        return {
            ItensHome: [],
            ItemHome: {},
            ListaGeral: [],
            ListaItensGeral: [],
            ListaEtiquetaImg: [],
            ListaFotosProd: [],
            slide: 0,
            sliding: null,
            parcelas: 0,
            parcelasTexto: '',
            artigos: [],
            artigo: {},
            TituloBlog: '',
            Titulo: '',
            SubTitulo: '',
            Texto: '',
            fundotela: '',
            Titulo2: '',
            SubTitulo2: '',
            Texto2: '',
            fundotela2: '',
            itenssobre: [],
            money: {
                decimal: ',',
                thousands: '.',
                precision: 2,
                masked: true
            },
            showModal: false,
            textomodal: [],
            tituloModal: '',
            textoModal: '',
            textoBtnmodal: '',
            textobusca: '',
            slideItens: '',
            slider: [],
            numeros: [],
            Produtos:[],
            ProdutosLancamentos:[],
        }
    },
    methods: {
        ValidaBusca() {
            if (this.textobusca != '') {
                this.$redirect("/produtosbusca/" + this.textobusca);
            }
            else {
                this.$mensagem_normal("Digite uma palavra-chave e clique na lupa para pesquisar.");
            }

        },
        validacss(value, txt) {

            let recebe = '';
            //console.log(value.homeMontaItemId + "-" + value.visivelMobile + "-" + value.visivelDesktop)
            if (value.visivelMobile && value.visivelDesktop) {
                recebe = ''
            }

            if (value.visivelMobile && !value.visivelDesktop) {
                recebe = ' hidden-lg hidden-xl '
            }

            if (!value.visivelMobile && value.visivelDesktop) {
                recebe = ' hidden-sm-md hidden-xs '
            }

            if (!value.visivelMobile && !value.visivelDesktop) {
                recebe = ' hidden-lg hidden-xl hidden-sm-md hidden-xs '
            }

            if (txt != null) {
                return recebe + txt;
            }
            else {
                return recebe;
            }


        },
        CarregaImagemUnica(value) {
            var retorno = this.ListaFotosProd.filter(x => x.produto.produtoId == parseInt(value))
            if (retorno != null) {
                retorno.sort(function (a, b) {
                    if (a.ordem > b.ordem) {
                        return 1;
                    }
                    if (a.ordem < b.ordem) {
                        return -1;
                    }
                    return 0;
                });
                return this.$Url + retorno[0].urlImg;
            }
            else {
                return this.$Url + "/imagens/indisponivel.jpg"
            }

        },
        initCarousel() {
            this.sliding = true
        },
        endSlide() {
            this.sliding = false
        },
        CienteModal() {
            let EntendiTropaSul = localStorage.getItem('entendiTropaSul');
            if (!EntendiTropaSul) {
                EntendiTropaSul = "entendiTropaSul";
                localStorage.setItem('entendiTropaSul', String(EntendiTropaSul))
                this.showModal = false;
            }
            else {
                if (EntendiTropaSul == "entendiTropaSul") {
                    this.showModal = false;
                }
                else {
                    this.showModal = true;
                }
            }
        },
    },
    created() {
        this.$http
            .get(this.$apiUrl + "/institucional/telas/sobre")
            .then((res2) => res2.json())
            .then((tiu2) => {
                this.Retorno = tiu2;

                if (this.Retorno != null) {
                    this.Titulo = this.Retorno[0].resumo1;
                    this.SubTitulo = this.Retorno[0].referencia;
                    this.Texto = this.Retorno[0].resumo2;
                    if (this.Retorno[0].institucionalImg != '') {
                        this.fundotela = this.Retorno[0].institucionalImg[0].urlImagem
                    }
                    else {
                        this.fundotela = '';
                    }
                }

            });

        this.$http
            .get(this.$apiUrl + "/institucional/telas/itens")
            .then((res2) => res2.json())
            .then((tiu2) => {
                this.Retorno = tiu2;

                if (this.Retorno != null) {
                    this.itenssobre = this.Retorno.sort(function (a, b) {
                        return a.ordem - b.ordem;
                    });
                }

            });

        this.$http
            .get(this.$apiUrl + "/institucional/telas/slider")
            .then((res) => res.json())
            .then((retornoAPI) => {
                this.slider = retornoAPI.filter(x => x.visivel).sort(function (a, b) {
                    if (a.ordem > b.ordem) {
                        return 1;
                    }
                    if (a.ordem < b.ordem) {
                        return -1;
                    }
                    return 0;
                }).reverse();
            });

        this.$http
            .get(this.$apiUrl + "/institucional/telas/artigos")
            .then((res) => res.json())
            .then((retornoAPI) => {
                this.artigos = retornoAPI.filter(x => x.visivel).sort(function (a, b) {
                    if (a.data1 > b.data1) {
                        return 1;
                    }
                    if (a.data1 < b.data1) {
                        return -1;
                    }
                    return 0;
                }).reverse();
            });

        this.$http
            .get(this.$apiUrl + "/institucional/telas/numeros")
            .then((res) => res.json())
            .then((retornoAPI) => {
                this.numeros = retornoAPI.filter((x) => x.visivel)

                this.numeros.sort(function (a, b) {
                    if (a.ordem > b.ordem) { return 1; }
                    if (a.ordem < b.ordem) { return -1; }
                    return 0;
                })
            });

        this.$http
            .get(this.$apiUrl + "/produto")
            .then((res) => res.json())
            .then((retornoAPI) => {
                this.Produtos = retornoAPI.filter((x) => x.visivel && x.imagens!=null && x.imagens!='')

                this.Produtos.sort(function () {
                    return .5 - Math.random();
                });

                this.ProdutosLancamentos = retornoAPI.filter((x) => x.visivel && x.destaque && x.imagens!=null && x.imagens!='')

                this.ProdutosLancamentos.forEach(produto => {
                        produto.imagens.sort((a, b) => a.ordem - b.ordem);
                    });

                this.ProdutosLancamentos.sort(function () {
                    return .5 - Math.random();
                });

            });

            let EntendiTropa = localStorage.getItem('entendiTropaSul');
            if (!EntendiTropa) {
            this.$http
                .get(this.$apiUrl + "/institucional/telas/cadastromodal")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.textomodal = tiu2;
                    if (this.textomodal != '') {
                        if (this.textomodal[0].visivel) {
                            this.tituloModal = this.textomodal[0].nome;
                            this.textoModal = this.textomodal[0].descricao1;
                            this.textoBtnmodal = this.textomodal[0].textoBtn1;
                            this.showModal = true;
                        }
                    }
                });
            }
            else {
                this.showModal = false;
            }

    },
    mounted() {

    },
}

</script>

<style>
@import '/css/produtos.css';

.carousel-caption {
    position: absolute !important;
    right: 15% !important;
    bottom: 11.25rem !important;
    left: 10% !important;
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: left !important;
}

.bg-color {
    background-color: #993366;
}

.imagem_produto {
    max-height: 250px;
}

.product_item {
    width: 100%;
    transition: all .2s ease-in;
    background-color: transparent;
}

.vfigure {
    position: absolute;
    float: left;
    top: 20px;
    height: 40px;
    z-index: 999;
    text-align: center;
}

.vfigcaption {
    font-size: 12px;
    left: 0px;
    top: 17px;
    color: #000000;
    width: 50px;
    position: absolute;
}

.vfigcaption2 {
    font-size: 12px;
    left: 0px;
    top: 17px;
    color: #000000;
    width: 50px;
    position: absolute;
}

.vfigure2 {
    position: absolute;
    float: left;
    top: 20px;
    height: 40px;
    z-index: 999;
    text-align: center;
}




/********************
    Responsive
    ********************/

/* Smartphones */
@media (max-width:767px) {
    .product_item {
        left: 0px;
    }

    .vfigure {
        position: absolute;
        float: left;
        top: 65px;
        height: 40px;
        z-index: 999;
        text-align: center;
    }

    .vfigcaption {
        font-size: 12px;
        left: 0px;
        top: 17px;
        color: #000000;
        width: 50px;
        position: absolute;
    }

    .peq {
        font-size: 9px;
        left: 0px;
        top: 10px;
        color: #000000;
        width: 36px;
        position: absolute;
    }

    .imgcelet {
        width: 35px;
    }

    .vfigure2 {
        position: absolute;
        float: left;
        top: 65px;
        height: 40px;
        z-index: 999;
        left: 30px;
    }


    .vfigcaption2 {
        font-size: 12px;
        left: 0px;
        top: 17px;
        color: #000000;
        width: 50px;
        position: absolute;
    }

    .new_arrivals_title {
        position: absolute;
        left: 0;
        top: 20px;
        font-size: 24px;
        font-weight: 500;
        margin-bottom: -29px;
    }
}

/* Tudo, menos desktop */
@media (max-width:1199px) {
    .product_item {
        height: min-content;
        padding-top: 60px;
        padding-bottom: 40px;
    }

    .product_price {
        margin-top: 0px;
    }
}

/* Desktop + Notbooks */
@media (min-width:1200px) {
    .product_item_alt {
        min-height: 240px;
    }
}

p {
    margin-top: 0;
    margin-bottom: 0rem !important;
}
</style>