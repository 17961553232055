<template>


<section class="carrinho">
    <section class="hero-wrap hero-wrap-2" style="background-image: url('/images/bg_1.jpg');height: 250px;">
        <div class="overlay"></div>
        <div class="container">
            <div class="row no-gutters slider-text justify-content-center" style="padding-top: 100px;">
                <div class="col-md-9 mb-5 text-center">

                    <h1 class="mb-0 bread">Quem Somos</h1>
                </div>
            </div>
        </div>
    </section>
    <section class="ftco-section ftco-about-section ftco-no-pt ftco-no-pb">
        <div class="container-xl">
            <div class="row">
                <div class="col-lg-6 col-xl-7 order-lg-last py-5 heading-section">
                    <div class="py-md-5 px-lg-5">
                        <span class="subheading"></span>
                        <h2 class="mb-4"><span>{{Titulo}}</span> {{SubTitulo}}</h2>
                        <p v-html="Texto"></p>
                        <ul>
                            <li v-for="item in itenssobre" :key="item.institucionalId">
                                    <span class="ion-ios-arrow-round-forward"></span> 
                                    {{item.nome}}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-5 d-flex align-items-end">
                    <div class="img w-100 pt-lg-5">
                        <img :src="$Url + fundotela" class="img-fluid" alt="">
                    </div>
                </div>
            </div>
        </div>
    </section>

    <Depoimentos />

    <section class="ftco-section" style="display: none;">
        <div class="container-xl">
            <div class="row justify-content-center mb-5">
                <div class="col-md-7 heading-section text-center">
                    <span class="subheading">TropaSul News</span>
                    <h2>Blog</h2>
                </div>
            </div>
            <div class="row g-md-0">
                    <div v-for="(artigo,index) in artigos" :key="artigo.institucionalId" class="col-md-6 col-lg-3 d-flex">
                        <div v-if="index%2==0" class="blog-entry justify-content-end">
                            <a href="#"
                                @click="$redirect_reload('/blogDetalhe/' + artigo.institucionalId + '/' + artigo.nome.replaceAll(' ','-').replaceAll('/',''))"
                                class="block-20 img d-flex align-items-end">
                                <img v-if="artigo.institucionalImg" :src="$Url + artigo.institucionalImg[0].urlImagem"
                                    alt="" style="height: 100%;">
                                <img v-else :src="$Url + '/imagens/indisponivel.pjg'" style="height: 100%;" alt="">
                            </a>
                            <div class="text">
                                <p class="meta">{{ currentDateTime(artigo.data1) }}</p>
                                <h3 class="heading mb-3"><a href="#"
                                        @click="$redirect_reload('/blogDetalhe/' + artigo.institucionalId + '/' + artigo.nome.replaceAll(' ','-').replaceAll('/',''))">{{
                                        artigo.nome }}</a></h3>
                                <p v-html="artigo.resumo1"></p>
                            </div>
                        </div>
                        <div v-else class="blog-entry justify-content-end d-md-flex flex-md-column-reverse">
                            <a href="#" @click="$redirect_reload('/blogDetalhe/' + artigo.institucionalId + '/' + artigo.nome.replaceAll(' ','-').replaceAll('/',''))" class="block-20 img d-flex align-items-end">
                                <img v-if="artigo.institucionalImg" style="height: 100%;" :src="$Url + artigo.institucionalImg[0].urlImagem"
                                    alt="">
                                <img v-else :src="$Url + '/imagens/indisponivel.pjg'" style="height: 100%;" alt="">
                            </a>
                            <div class="text">
                                <p class="meta">{{ currentDateTime(artigo.data1) }}</p>
                                <h3 class="heading mb-3"><a href="#"
                                        @click="$redirect_reload('/blogDetalhe/' + artigo.institucionalId + '/' + artigo.nome.replaceAll(' ','-').replaceAll('/',''))">{{
                                        artigo.nome }}</a></h3>
                                <p v-html="artigo.resumo1"></p>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </section>
   <div class="pb-5"></div>
    <Newsletter  />

</section>

</template>

<script>

  import Depoimentos from '../shared/Depoimentos.vue'
  import Newsletter from '../shared/Newsletter.vue'
  import moment from 'moment'

    export default {
        components: {
            Depoimentos,
            Newsletter,
        },
        data() {
            return {
                Retorno: [],
                Titulo: '',
                SubTitulo:'',
                Texto: '',
                fundotela:'',
                Titulo2: '',
                SubTitulo2:'',
                Texto2: '',
                fundotela2:'',
                itenssobre:[],
                artigos: [],
            }
        },
        methods: {
            currentDateTime(vdate) {
                return moment(vdate).format('DD/MM/YYYY')
            },
        },
        created() {

            this.$http
                .get(this.$apiUrl + "/institucional/telas/empresa")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Retorno = tiu2;

                    if (this.Retorno != null) {
                        this.Titulo = this.Retorno[0].nome;
                        this.SubTitulo = this.Retorno[0].referencia;
                        this.Texto = this.Retorno[0].resumo2;
                        if (this.Retorno[0].institucionalImg != '') {
                            this.fundotela = this.Retorno[0].institucionalImg[0].urlImagem
                        }
                        else {
                            this.fundotela = '';
                        }
                    }

                });

                this.$http
                .get(this.$apiUrl + "/institucional/telas/itenssobre")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Retorno = tiu2;

                    if (this.Retorno != null) {
                        this.itenssobre = this.Retorno.sort(function (a, b) {
                        return a.ordem - b.ordem;
                    });
                    }

                });

                this.$http
                .get(this.$apiUrl + "/institucional/telas/artigos")
                .then((res) => res.json())
                .then((retornoAPI) => {
                    this.artigos = retornoAPI.filter(x => x.visivel).sort(function (a, b) {
                        return a.data1 - b.data1;
                    }).reverse();
                });


                
        },
        mounted() {

        },
    }

</script>

<style scoped>

</style>