<template>
<footer class="ftco-footer">
  <div class="container-xl">
    <div class="row mb-5 pb-5 justify-content-between">
      <div class="col-md-6 col-lg-4">
        <div class="ftco-footer-widget mb-4">
          <h2 class="ftco-heading-2 logo d-flex">
            <div style="padding: 10px;background-color:#FFFFFF;border-radius: 15px;">
                <router-link to="/">
                    <img src="/images/logo1.png" class="img-fluid" style="width: 250px;" />
                </router-link>
            </div>
         </h2>
         <div class="block-23 mb-3">
            <ul>
              <li><a href="#"><span class="icon fa fa-paper-plane"></span><span class="text" v-html="informacoes[0].resumo1"></span></a></li>
          </ul>
      </div>
         <ul class="ftco-footer-social list-unstyled mt-2">
            <li><a v-if="Linke[0].visivel" :href="Linke[0].nome" target="_blank"><span class="fa fa-linkedin"></span></a></li>
            <li><a v-if="Face[0].visivel" :href="Face[0].nome" target="_blank"><span class="fa fa-facebook"></span></a></li>
            <li><a v-if="Insta[0].visivel" :href="Insta[0].nome" target="_blank"><span class="fa fa-instagram"></span></a></li>
            <li><a v-if="Yout[0].visivel" :href="Yout[0].nome" target="_blank"><span class="fa fa-youtube"></span></a></li>
         </ul>
       </div>
     </div>
     <div class="col-md-6 col-lg-2">
    <div class="ftco-footer-widget mb-4">
        <h2 class="ftco-heading-2">Menu</h2>
        <ul class="list-unstyled">
            <li><a href="#" @click="$redirect_reload('/')"><span class="fa ion-ios-arrow-round-forward me-3"></span>Home</a></li>
            <li><a href="#" @click="$redirect_reload('/sobre')"><span class="fa ion-ios-arrow-round-forward me-3"></span>Quem Somos</a></li>
            <li><a href="#" @click="$redirect_reload('/Produtos')"><span class="fa ion-ios-arrow-round-forward me-3"></span>Produtos</a></li>
          <!--   <li><a href="" @click="$redirect_reload('/blog')"><span class="fa ion-ios-arrow-round-forward me-3"></span>Blog</a></li> -->
            <li><a href="#" @click="$redirect_reload('/contato')"><span class="fa ion-ios-arrow-round-forward me-3"></span>Contato</a></li>
        </ul>
    </div>
    </div>
<div class="col-md-6 col-lg-3">
    <div class="ftco-footer-widget mb-4">
       <h2 class="ftco-heading-2">Informações</h2>
       <ul class="list-unstyled">
           <li v-for="linhaRet in Retorno" :key="linhaRet.institucionalId">
              <a href="#" @click="$redirect_reload('/informacoesgerais/'+ linhaRet.institucionalId + '/' + linhaRet.nome.replaceAll(' ','-').replaceAll('/',''))">
                <span class="fa ion-ios-arrow-round-forward me-3"></span>{{ linhaRet.nome }}
              </a>
           </li>
       </ul>
    </div>
 </div>
 <div class="col-md-6 col-lg-3">
    <div class="ftco-footer-widget mb-4">
       <h2 class="ftco-heading-2">Acessos</h2>
       <ul class="list-unstyled">
            <li>
                <router-link to="/login"><span class="fa ion-ios-arrow-round-forward me-3"></span>Login</router-link>
            </li>
           <!--  <li>
                <router-link to="/meusDados"><span class="fa ion-ios-arrow-round-forward me-3"></span>Meus Dados</router-link>
            </li>-->
            <li> 
                <router-link to="/carrinho"><span class="fa ion-ios-arrow-round-forward me-3"></span>Meu Carrinho</router-link>
            </li>
         <!--    <li>
                <router-link to="/meusPedidos"><span class="fa ion-ios-arrow-round-forward me-3"></span>Meus Pedidos</router-link>
            </li> -->
        </ul>
    </div>
 </div>
</div>
</div>
<div class="container-fluid px-0 py-5 bg-darken">
 <div class="container-xl">
    <div class="row">
     <div class="col-md-12 text-center">
        <a href="https://avancedigital.com.br/" target="_blank">
            <img class="logo_avance" src="/images/logoRodape.png">
        </a>   
    </div>
</div>
</div>
</div>
</footer>
</template>

<script>
    export default {
        data() {
            return {
                Segmentos: [],
                informacoes: [],
                Face: [],
                Insta: [],
                Yout: [],
                Linke:[],
                logo_footer: '@/assets/img/10.png',
                logSelo: '',
                resultSelo: [],
                Retorno: [],
                linhaRet: {},
            }
        },
        created() {

            this.$http
                .get(this.$apiUrl + "/institucional/telas/informacoes")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.informacoes = tiu2;
                });


            this.$http
                .get(this.$apiUrl + "/institucional/telas/facebook")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Face = tiu2
                });

            this.$http
                .get(this.$apiUrl + "/institucional/telas/instagram")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Insta = tiu2
                });

            this.$http
                .get(this.$apiUrl + "/institucional/telas/youtube")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Yout = tiu2
                });

                 this.$http
                .get(this.$apiUrl + "/institucional/telas/linkedin")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Linke = tiu2
                });

            this.$http
                .get(this.$apiUrl + "/institucional/telas/informacoesgerais")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Retorno = tiu2.filter(x => x.visivel);
                });
        },
        mounted() {

        },
        components: {
        },
    }
</script>

<style scoped>

    .social-icon {
        background-color: #fff;
        border: 1px solid #fff;
        border-radius: 50%;
        margin-left: 8px;
        transition: transform .2s ease-in;
    }

        .social-icon:hover {
            transform: scale(1.1);
        }
</style>