<template>
    <div>
        <section class="hero-wrap hero-wrap-2" style="background-image: url('/images/bg_1.jpg');height: 250px;">
            <div class="overlay"></div>
            <div class="container">
                <div class="row no-gutters slider-text justify-content-center" style="padding-top: 100px;">
                    <div class="col-md-9 mb-5 text-center">
                        <h1 class="mb-0 bread">{{ Titulo }}</h1>
                    </div>
                </div>
            </div>
        </section>
        <section class="ftco-section">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 sidebar pl-md-4">
                        <div class="sidebar-box">
                            <h3 class="mb-5">Informações Gerais</h3>
                            <div v-for="linhaRet in Retorno" :key="linhaRet.institucionalId" class="block-21 mb-4 d-flex">
                                <div class="text">
                                    <h3 class="heading">
                                        <a href="" @click.prevent="carregaArtigo(linhaRet.institucionalId)" class="texto" style="font-weight: 800;">
                                            {{ linhaRet.nome }}
                                        </a>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8 blog-single">
                        <h2 class="mb-3">{{ Titulo }}</h2>
                        <p v-html="Texto"></p>
                    </div> <!-- .col-md-8 -->
    
                </div>
            </div>
        </section> <!-- .section -->
    </div>
</template>

<script>
export default {
    components: {
    },
    data() {
        return {
            InstitucionalId: this.$route.params.id,
            Retorno: [],
            linhaRet: {},
            Dados: [],
            Titulo: '',
            Texto: '',
        }
    },
    methods: {
        carregaArtigo(value) {
            this.$http
                .get(this.$apiUrl + "/institucional/" + parseInt(value, 10))
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Dados = tiu2;
                    if (this.Dados != null) {
                        this.Titulo = this.Dados.nome;
                        this.Texto = this.Dados.descricao1;
                    }
                });
        },
    },
    created() {
        this.$http
            .get(this.$apiUrl + "/institucional/telas/informaçõesgerais")
            .then((res2) => res2.json())
            .then((tiu2) => {
                this.Retorno = tiu2.filter(x => x.visivel);
            });

        if (typeof this.$route.params.id != 'undefined') {
            this.carregaArtigo(this.$route.params.id);
        }

    },
    mounted() {
    },
}
</script>

<style scoped>
</style>