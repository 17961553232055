<template>

    <section class="carrinho">
    
        <section class="hero-wrap hero-wrap-2"
            :style="{ backgroundImage: 'url(' + $Url + fundotela2 + ')',backgroundSize:'cover',backgroundRepeat:'no-repeat',height: '250px' }">
            <div class="overlay"></div>
            <div class="container">
                <div class="row no-gutters slider-text justify-content-center" style="padding-top: 100px;">
                    <div class="col-md-9 mb-5 text-center">
                        <h1 class="mb-0 bread">Contato</h1>
                    </div>
                </div>
            </div>
        </section>
    
        <section class="ftco-section ftco-no-pt ftco-no-pb">
            <div class="container-xl-fluid">
                <div class="row no-gutters justify-content-center">
                    <div class="col-md-12">
                        <div class="wrapper">
                            <div class="row g-0">
                                <div class="col-lg-12">
                                    <div class="contact-wrap w-100 p-md-5 p-4">
                                        <h3>{{TituloForm}}</h3>
                                        <p class="mb-4">{{Titulo}}</p>
                                        <div class="row mb-4" style="display: none;">
                                            <div class="col-md-4">
                                                <div class="dbox w-100 d-flex align-items-start">
                                                    <div class="text">
                                                        <p><span>Endereço:</span>{{Texto}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="dbox w-100 d-flex align-items-start">
                                                    <div class="text">
                                                        <p><span>E-mail:</span> <a href="">{{Emailform}}</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="dbox w-100 d-flex align-items-start">
                                                    <div class="text">
                                                        <p><span>Telefone:</span> <a href="">{{Telefoneform}}
                                                            </a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <form id="contactForm" name="contactForm" class="contactForm">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <input type="text" class="form-control" v-model="Nome"
                                                            placeholder="Nome">
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <input type="email" class="form-control" v-model="Email"
                                                            placeholder="E-mail">
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <input type="text" class="form-control" v-model="Telefone"
                                                            placeholder="Telefone">
                                                    </div>
                                                </div>
                                                <div class="col-md-12 pt-3">
                                                    <div class="form-group">
                                                        <textarea name="message" class="form-control" v-model="Mensagem"
                                                            cols="30" rows="4" placeholder="Mensagem"></textarea>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 pt-3">
                                                    <div class="form-group">
                                                        <button class="btn btn-primary" @click="Enviar()">
                                                            Enviar
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 mb-2" style="text-align:center;">
                                                    <span style="color:#AA6144;">
                                                        {{ Resposta }}
                                                    </span>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                              <!--   <div class="col-lg-6 d-flex align-items-stretch">
                                    <iframe
                                        :src="'https://www.google.com/maps/embed/v1/place?key=AIzaSyDmXp4J53f9Wt7iHX7dzIn7rHG7yL-EXXo&q=' + Texto"
                                        width="100%" height="650px" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
                                        tabindex="0">
                                    </iframe>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>
<script>

    export default {
        components: {
        },
        data() {
            return {
                Retorno: [],
                Titulo:'',
                TituloForm:'',
                Texto:'',
                Telefoneform:'',
                Emailform:'',
                contatoEmail:'',

                Nome: '',
                Email: '',
                Telefone: '',
                Mensagem: '',
                Resposta: '',
                fundotela:'',
                fundotela2:'',
            }
        },
        methods: {
            Limpar() {
                this.$redirect_reload("/contato");
            },
            Enviar() {
                this.Resposta = "";
                if (this.Email == "") {
                    this.Resposta = "Por favor preencha o e-mail";
                } else if (this.Telefone == "") {
                    this.Resposta = "Por favor preencha Telefone";
                } else if (this.Mensagem == "") {
                    this.Resposta = "Por favor preencha Mensagem";
                } else {
                    let tmp = { ToEmail: this.contatoEmail, Subject: 'Contato do Site', Body: "Nome: " + this.Nome + " | Telefone: " + this.Telefone + " | E-mail: " + this.Email + " | Mensagem:  " + this.Mensagem };
                    this.$http
                        .post(
                            this.$apiUrl + "/contato/enviamail/" + 'TropaSul', tmp
                        )
                        .then(
                            (response) => {
                                if (response.ok) {
                                    this.$mensagem_normal("E-mail enviado com sucesso");
                                    this.Nome = '';
                                    this.Telefone = '';
                                    this.Email = '';
                                    this.Mensagem = '';

                                }
                            },
                            (error) => {
                                console.log(error);
                                this.Resposta = error.bodyText;
                            }
                        );
                }
            }
        },
        created() {

            this.$http
                .get(this.$apiUrl + "/institucional/telas/contato")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Retorno = tiu2;
                    if (this.Retorno != null) {
                        this.Titulo = this.Retorno[0].campoExtra6;
                        this.TituloForm = this.Retorno[0].campoExtra7;
                        this.Texto = this.Retorno[0].campoExtra3;
                        this.Telefoneform = this.Retorno[0].campoExtra4;
                        this.Emailform = this.Retorno[0].campoExtra5;
                        this.contatoEmail = this.Retorno[0].referencia;
                       
                         if (this.Retorno[0].institucionalImg != '') {
                            this.fundotela2 = this.Retorno[0].institucionalImg[0].urlImagem
                        }
                        else {
                            this.fundotela2 = '';
                        }
                    }
                });

        },
        mounted() {
        },
    }
</script>
<style scoped>
</style>
