<template>

    <section class="carrinho">
    
        <section class="hero-wrap hero-wrap-2" style="background-image: url('images/bg_1.jpg');height: 250px;">
            <div class="overlay"></div>
            <div class="container">
                <div class="row no-gutters slider-text justify-content-center" style="padding-top: 100px;">
                    <div class="col-md-9 mb-5 text-center">
                        <h1 class="mb-0 bread">Blog</h1>
                    </div>
                </div>
            </div>
        </section>
    
        <section class="ftco-section">
            <div class="container-xl">
                <div class="row g-md-0">
                    <div v-for="(artigo,index) in artigos" :key="artigo.institucionalId" class="col-md-6 col-lg-3 d-flex">
                        <div v-if="index%2==0" class="blog-entry justify-content-end">
                            <a href="#"
                                @click="$redirect_reload('/blogDetalhe/' + artigo.institucionalId + '/' + artigo.nome.replaceAll(' ','-').replaceAll('/',''))"
                                class="block-20 img d-flex align-items-end">
                                <img v-if="artigo.institucionalImg" :src="$Url + artigo.institucionalImg[0].urlImagem"
                                    alt="" style="height: 100%;">
                                <img v-else :src="$Url + '/imagens/indisponivel.pjg'" style="height: 100%;" alt="">
                            </a>
                            <div class="text">
                                <p class="meta">{{ currentDateTime(artigo.data1) }}</p>
                                <h3 class="heading mb-3"><a href="#"
                                        @click="$redirect_reload('/blogDetalhe/' + artigo.institucionalId + '/' + artigo.nome.replaceAll(' ','-').replaceAll('/',''))">{{
                                        artigo.nome }}</a></h3>
                                <p v-html="artigo.resumo1"></p>
                            </div>
                        </div>
                        <div v-else class="blog-entry justify-content-end d-md-flex flex-md-column-reverse">
                            <a href="#" @click="$redirect_reload('/blogDetalhe/' + artigo.institucionalId + '/' + artigo.nome.replaceAll(' ','-').replaceAll('/',''))" class="block-20 img d-flex align-items-end">
                                <img v-if="artigo.institucionalImg" style="height: 100%;" :src="$Url + artigo.institucionalImg[0].urlImagem"
                                    alt="">
                                <img v-else :src="$Url + '/imagens/indisponivel.pjg'" style="height: 100%;" alt="">
                            </a>
                            <div class="text">
                                <p class="meta">{{ currentDateTime(artigo.data1) }}</p>
                                <h3 class="heading mb-3"><a href="#"
                                        @click="$redirect_reload('/blogDetalhe/' + artigo.institucionalId + '/' + artigo.nome.replaceAll(' ','-').replaceAll('/',''))">{{
                                        artigo.nome }}</a></h3>
                                <p v-html="artigo.resumo1"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
        </section>
    </section>
</template>

<script>
    import moment from 'moment'

    export default {
        components: {
        },
        data() {
            return {
                artigos: [],
                fundotela:'',
            }
        },
        methods: {
            currentDateTime(vdate) {
                return moment(vdate).format('DD/MM/YYYY')
            },
        },
        created() {
            // Conteudo Artigos
            this.$http
                .get(this.$apiUrl + "/institucional/telas/artigos")
                .then((res) => res.json())
                .then((retornoAPI) => {
                    this.artigos = retornoAPI.filter(x => x.visivel).sort(function (a, b) {
                        return a.data1 - b.data1;
                    }).reverse();
                });
        },
        mounted() {

        },
    }

</script>

<style scoped>
</style>