<template>

    <section class="carrinho">
        <CheckAcesso />

        <section :style="{ backgroundImage: 'url(' + $Url + fundotela + ')',backgroundColor:'#e8eaf6',backgroundSize:'100%',backgroundRepeat:'no-repeat' }">

            <div class="container">
                <div class="row py-5">
                    <div class="col-xl-6 hidden-sm-md">
                        <div class="carrinho-bg set-img">

                        </div>
                    </div>
                    <div class="col-xl-6">
                        <div class="box_content">
                            <h3 class="titulo-principal text-center mb-4">{{ Titulos[0].nome }}</h3>

                            <!-- Status -->
                            <div class="status-novaConta px-md-5">
                                <div class="status-item pos-item2">
                                    <div class="status-number"><i class="fa fa-check"></i></div>
                                    <span class="status-text">Login</span>
                                </div>
                                <div class="status-item pos-item2">
                                    <div class="status-number"><span>2</span></div>
                                    <span class="status-text">Endereço</span>
                                </div>
                                <div class="status-item active">
                                    <div class="status-number"><span>3</span></div>
                                    <span class="status-text">Pagamento</span>
                                </div>
                            </div>

                            <!-- Dados do Cartão -->
                            <div class="form-group row align-items-center mb-3">
                                <label for="inputPGTO" class="col-sm-5 col-form-label label-pagto2">Forma Pagamento</label>
                                <div class="col-sm-7">
                                    <v-select v-model="ListaformasPagamentoselected" @input="MudaListaformasPagamento"
                                              :options="ListaformasPagamento"
                                              :value="ListaformasPagamento"
                                              single-line
                                              label="texto" class="style-chooser w-100"></v-select>
                                </div>
                            </div>
                            <PagamentoYapay v-if="showyapay==true" />
                            <PagamentoCielo v-if="showcielo==true" />
                            <PagamentoPaypal v-if="showpaypal==true" />
                            <PagamentoBancos v-if="showbancos==true" :BancoId="OperacaoId" :key="componentKey" />
                            <PagamentoPix v-if="showPix==true" :PixId="OperacaoId" :key="componentKeypix" />
                            <PagamentoPagseguro v-if="showpagseguro==true" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </section>

</template>

<script>
    import CheckAcesso from '@/components/site/shared/CheckAcesso.vue'
    import vSelect from 'vue-select'
    import PagamentoYapay from '@/components/site/shared/PagamentoYapay.vue'
    import PagamentoCielo from '@/components/site/shared/PagamentoCielo.vue'
    import PagamentoPaypal from '@/components/site/shared/PagamentoPaypal.vue'
    import PagamentoBancos from '@/components/site/shared/PagamentoBancos.vue'
    import PagamentoPix from '@/components/site/shared/PagamentoPix.vue'
    import PagamentoPagseguro from '@/components/site/shared/PagamentoPagseguro.vue'

    export default {
        components: {
             vSelect,
            PagamentoYapay,
            PagamentoCielo,
            PagamentoPaypal,
            PagamentoBancos,
            PagamentoPix,
            PagamentoPagseguro,
            CheckAcesso
        },
        data() {
            return {
                ListaformasPagamentoOperadora: [],
                ListaformasPagamentoBanco: [],
                ListaformasPagamentoPix: [],
                ListaformasPagamento: [],
                OperacaoId: 0,
                Operacao: '',
                ListaformasPagamentoselected: null,
                showyapay: false,
                showpaypal: false,
                showbancos: false,
                showPix: false,
                showpagseguro: false,
                showcielo:false,
                componentKey: 0,
                componentKeypix: 0,
                Titulos: [],
                fundotela: '',
            }
        },
        methods: {
            MudaListaformasPagamento(value) {
                if (value != null) {
                    this.OperacaoId = value.OperacaoId;
                    this.Operacao = value.Operacao;
                    if (parseInt(this.OperacaoId, 10) == 1 && this.Operacao == "cadOperadora") {
                        this.showyapay = true;
                        this.showpaypal = false;
                        this.showbancos = false;
                        this.showPix = false;
                        this.showpagseguro = false;
                        this.showcielo = false;
                    } else if (parseInt(this.OperacaoId, 10) == 3 && this.Operacao == "cadOperadora") {
                        this.showyapay = false;
                        this.showpaypal = true;
                        this.showbancos = false;
                        this.showPix = false;
                        this.showpagseguro = false;
                        this.showcielo = false;
                    } else if (parseInt(this.OperacaoId, 10) == 2 && this.Operacao == "cadOperadora") {
                        this.showyapay = false;
                        this.showpaypal = false;
                        this.showbancos = false;
                        this.showPix = false;
                        this.showpagseguro = true;
                        this.showcielo = false;
                    } else if (parseInt(this.OperacaoId, 10) == 4 && this.Operacao == "cadOperadora") {
                        this.showyapay = false;
                        this.showpaypal = false;
                        this.showbancos = false;
                        this.showPix = false;
                        this.showpagseguro = false;
                        this.showcielo = true;
                    } else if (this.Operacao == "cadBanco") {
                        this.showyapay = false;
                        this.showpaypal = false;
                        this.showbancos = true;
                        this.showPix = false;
                        this.showpagseguro = false;
                        this.showcielo = false;
                        this.forceRerender();
                    } else if (this.Operacao == "cadPix") {
                        this.showyapay = false;
                        this.showpaypal = false;
                        this.showbancos = false;
                        this.showPix = true;
                        this.showpagseguro = false;
                        this.showcielo = false;
                        this.forceRerenderPix();
                    }
                    else {
                        this.OperacaoId = 0;
                        this.Operacao = '';
                        this.showyapay = false;
                        this.showpaypal = false;
                        this.showbancos = false;
                        this.showPix = false;
                        this.showpagseguro = false;
                        this.showcielo = false;
                    }
                } else {
                    this.OperacaoId = 0;
                    this.Operacao = '';
                    this.showyapay = false;
                    this.showpaypal = false;
                    this.showbancos = false;
                    this.showPix = false;
                    this.showpagseguro = false;
                    this.showcielo = false;
                }
            },
            forceRerender() {
                this.componentKey += 1;
            },
            forceRerenderPix() {
                this.componentKeypix += 1;
            },
        },
        created() {

            let dadosArmazenados = localStorage.getItem(this.$UsuarioDados);
            if (!dadosArmazenados) {
                dadosArmazenados = this.DadosCompra;
                localStorage.setItem(this.$UsuarioDados, JSON.stringify(dadosArmazenados))
                this.$redirect_reload("/meuspedidos");
            } else {
                dadosArmazenados = JSON.parse(dadosArmazenados);
                if (dadosArmazenados.produtos == '') {
                    this.$redirect_reload("/meuspedidos");
                }
            }


            //----------------- Titulos
            this.$http
                .get(this.$apiUrl + "/institucional/telas/textopagamento")
                .then((res) => res.json())
                .then((retornoAPI) => {
                    this.Titulos = retornoAPI;

                    if (this.Titulos[0].institucionalImg != '') {
                        this.fundotela = this.Titulos[0].institucionalImg[0].urlImagem
                    }
                    else {
                        this.fundotela = '';
                    }
                });

            let _formasPagamento = []
            //carrega informações da lista de opções de pagamento
            this.$http
                .get(this.$apiUrl + "/configoperadora")
                .then((res2) => res2.json())
                .then((tiu2) => {

                    //carrega informações da lista de opções de pagamento
                    this.$http
                        .get(this.$apiUrl + "/configpix")
                        .then((res2) => res2.json())
                        .then((tiu3) => {
                            this.ListaformasPagamentoPix = tiu3.filter(x => x.status)
                            if (this.ListaformasPagamentoPix != null) {
                                this.ListaformasPagamentoPix.forEach((item) => {
                                    _formasPagamento.push({
                                        OperacaoId: item.cadPix.cadPixId,
                                        Operacao: "cadPix",
                                        texto: item.cadPix.nomePix,
                                    })
                                });
                            }

                            this.ListaformasPagamentoOperadora = tiu2.filter(x => x.status)
                            if (this.ListaformasPagamentoOperadora != null) {
                                this.ListaformasPagamentoOperadora.forEach((item) => {
                                    _formasPagamento.push({
                                        OperacaoId: item.cadOperadora.cadOperadoraId,
                                        Operacao: "cadOperadora",
                                        texto: "Cartão de Crédito", ///item.cadOperadora.nomeOperadora,
                                    })
                                });
                            }

                            //carrega informações da lista de opções de pagamento
                            this.$http
                                .get(this.$apiUrl + "/configbanco")
                                .then((res2) => res2.json())
                                .then((tiu2) => {
                                    this.ListaformasPagamentoBanco = tiu2.filter(x => x.status)
                                    if (this.ListaformasPagamentoBanco != null) {
                                        this.ListaformasPagamentoBanco.forEach((item) => {
                                            _formasPagamento.push({
                                                OperacaoId: item.cadBanco.cadBancoId,
                                                Operacao: "cadBanco",
                                                texto: item.cadBanco.nomeBanco,
                                            })
                                        });
                                    }



                                });
                        });

                });

            this.ListaformasPagamento = _formasPagamento;
            this.ListaformasPagamento.sort(function (a, b) {
                return a.texto - b.texto;
            });
        }
    }

</script>

<style scoped>

    @import '/css_cart/carrinho.css';
    @import '/css_cart/nova_conta.css';
</style>