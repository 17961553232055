<template>
  <div id="app">

    <NavMenu />
    
    <transition name="fadeIn">
      <router-view></router-view>
    </transition>
    
    <Footer />
    
  </div>
</template>

<script>

  import NavMenu from './components/site/shared/NavMenu.vue'
  import Footer from './components/site/shared/Footer.vue'

  export default {
        name: 'App',
        metaInfo: {
            htmlAttrs: {
                lang: 'pt-br'
            },
            meta: [
                { charset: 'utf-8' },
                { name: 'viewport', content: 'width=device-width,initial-scale=1.0' }
            ]
        },
    components: {
      NavMenu,
      Footer
    },

 
    
  }

</script>

<style> 
@import '/css/animate.css'; 
@import '/css/flaticon.css'; 
@import '/css/tiny-slider.css'; 
@import '/css/glightbox.min.css'; 
@import '/css/aos.css'; 
@import '/css/style.css'; 

/* Carrinho */
@import '/css_cart/carrinho.css';
@import '/css_cart/login.css';
@import '/css_cart/minha_conta.css';
@import '/css_cart/nova_conta.css';
@import '/css_cart/pedido_realizado.css';
@import '/css_cart/status_conta.css';



/* ---------------- Styles */
/* ---------  */

.vm {
    max-width: 600px !important;
    height: 260px !important;
}

.ql-align-justify {
  text-align: justify !important;
  }
  .ql-align-right{
  text-align: right !important;
  }

  .ql-align-center{
  text-align: center !important;
  }

  .ql-size-small{
    font-size: 12px;
    line-height: 1;
  }
/*---------- Animations */
.fadeIn-enter {
  opacity: 0;
}
.fadeIn-enter-active {
  transition: opacity .8s;
}
.fadeIn-enter-to {
opacity: 1;
}
/*---------- End Animations */
ul.lista-sidebar_links {
  padding-left: 0;
}

.set-img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

a {
  text-decoration: none !important;
}

.ajusteFontMenu {
  color:#ffffff !important;
  font-size: 16px;
}
.ajusteMenu {
    padding: 10px 15px 10px 15px;
}

span.subtexto {
  color: #202020;
  font-size: 14px;
  font-weight: 600;
}

.link_decoration {
  text-decoration: underline !important;
  transition: all 2s ease-in;
}
.link_decoration:hover {
  text-decoration: none !important;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #AA6144 !important;
}

.main_nav_desktop li a.active {
	color: #fff !important;
	font-weight: 500;
}

ul.cat_menu {
  padding-left: 0 !important;
  min-width: 150px;
}

.form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #000000 !important;
    outline: 0;
   box-shadow: 0 0 0 0rem rgb(13 110 253 / 25%) !important;
}

/********************
Responsive
********************/

/* Smartphones */
@media (max-width:767px) {
  .hidden-sm {
    display: none !important;
  }
  .product_item {
    left: 0px;
  }

  .slider-hero .work .img {
    width: 100%;
    height: 267px !important;
    min-height: 100%;
    position: relative;
    z-index: 0;
    overflow: hidden;
    background-position: top center;
    -webkit-box-shadow: 0px 20px 35px -30px rgb(0 0 0 / 26%);
    box-shadow: 0px 20px 35px -30px rgb(0 0 0 / 26%);
}

.slider-hero {
    position: relative;
    height: 267px !important;
    min-height: 100%;
}
}

/* Smartphones + tablets */
@media (max-width:991px) {
  .hidden-sm-md {
    display: none !important;
  }

}

/* Tablets */
@media (min-width:768px) and (max-width:991px) {
  .hidden-md {
    display: none !important;
  }

}

/* Netbooks */
@media (min-width:992px) and (max-width:1199px) {
  .hidden-lg {
    display: none !important;
  }

}

/* Desktop + Notbooks */
@media (min-width:1200px) {
  .hidden-xl {
    display: none !important;
  }
}


</style>
