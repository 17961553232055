<template>

    <section class="carrinho">

        <CheckAcesso />
        <section class="main-minha_conta">
            <div class="container">
                <div class="row">
                    <!-- Sidenav -->
                    <div class="col-lg-2 col-xl-3" style="background-color: #cccccc;">
                        <ul class="lista-sidebar_links" style="list-style-type: none;padding:10px;">
                            <li style="border-bottom: 1px solid #f9f9f9 ;padding:10px;"><router-link to="/meusDados" class="texto">Meus Dados</router-link></li>
                            <li style="border-bottom: 1px solid #f9f9f9 ;padding:10px;"><router-link to="/meusPedidos" class="texto active">Meus Pedidos</router-link></li>
                        </ul>
                    </div>
                    <!-- Main -->
                    <div class="col-lg-10 col-xl-9 mt-5 mt-lg-0">
                        <h3 class="titulo-principal text-center mb-4">Minha Conta</h3>
                        <h6 class="subtitulo h5 mb-4">Meus Pedidos</h6>
                        <!-- Lista de Pedidos -->
                        <ul class="listaPedidos" style="list-style-type: none;">
                            <li v-for="pedidocliente in PedidosCliente" :key="pedidocliente.pedidoId">
                                <hr>
                                <div class="row">
                                    <div class="col-md-4 col-lg-2 mb-4 mb-lg-0">
                                        <div class="pedido_id">
                                            <i class="fa fa-clipboard text-success fa-2x"></i>
                                            <div style="padding-top:6px;">
                                                <h6 class="d-inline-block">Ped: {{ pedidocliente.pedidoId }}</h6>
                                                - {{ pedidocliente.formaPgto }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-lg-2 d-flex align-items-center">
                                        <div class="pedido_dados">{{ FormataData(pedidocliente.dataPedido) }}</div>
                                    </div>
                                    <div class="col-md-2 col-lg-2 d-flex align-items-center">
                                        <div class="pedido_dados">Qtde: {{ calculaQtde(pedidocliente) }}</div>
                                    </div>
                                    <div class="col-md-3 col-lg-2 d-flex align-items-center">
                                        <div class="pedido_dados">R$ {{ parseFloat(calculaValor(pedidocliente)).toFixed(2).toString().replace('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</div>
                                    </div>
                                    <div class="col-lg-2 mt-4 mt-lg-0 d-flex align-items-center">
                                        <router-link :to="/meusDocumentos/ + pedidocliente.pedidoId" class="btn btn-info">Envio de Documentos</router-link>
                                    </div>
                                    <div class="col-lg-2 mt-4 mt-lg-0 d-flex align-items-center">
                                        <router-link :to="/meusPedidosDetalhe/ + pedidocliente.pedidoId" class="btn btn-success">Ver Detalhes</router-link>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

    </section>

</template>

<script>

    import CheckAcesso from '@/components/site/shared/CheckAcesso.vue'

    import moment from 'moment';
    import { VMoney } from 'v-money'

    export default {
        components: {
            CheckAcesso,

        },
        directives: { money: VMoney },
        data() {
            return {
                ClienteId: 0,
                PedidosCliente: [],
                pedidocliente: {},
                UltimoPedido: {},
                TotalPedido: 0,
                PedidosEndereco: [],
                PedidosAgendamento: [],
            }
        },
        methods: {
            validaHora(value) {
                var _hora = value.toString();
                if (_hora.length == 1)
                    _hora = "0" + _hora;
                return _hora;
            },
            FormataData(value) {
                return moment(value).format('DD/MM/YYYY')
            },
            calculaValor(value) {
                let valor = 0
                let recebe = value.listaPedidoItem;
                recebe.forEach((item) => {
                    valor += item.valorTotalProduto * item.qtdeProduto
                });

                let recebe2 = value.listaPedidoItemAdicional;
                recebe2.forEach((item) => {
                    valor += item.valorTotalProduto * item.qtdeProduto
                });

                let recebe3 = value.listaPedidoItemManual;
                recebe3.forEach((item) => {
                    valor += item.valorTotalProduto * item.qtdeProduto
                });
                valor += value.valorFrete

                return (valor - value.valorCupom)
            },
            calculaQtde(value) {
                let qtde = 0
                let recebe = value.listaPedidoItem;
                recebe.forEach((item) => {
                    qtde += item.qtdeProduto
                });

                let recebe2 = value.listaPedidoItemAdicional;
                recebe2.forEach((item) => {
                    qtde += item.qtdeProduto
                });

                let recebe3 = value.listaPedidoItemManual;
                recebe3.forEach((item) => {
                    qtde += item.qtdeProduto
                });
                return qtde
            },
        },
        created() {

            let usuarioTmp = JSON.parse(this.$cookie.get(this.$UsuarioCookie));
            if (usuarioTmp != null) {
                this.ClienteId = parseInt(usuarioTmp.usuarioId, 10)

                //carrega Pedido do cliente
                this.$http
                    .get(this.$apiUrl + "/Pedido/completo")
                    .then((res2) => res2.json())
                    .then((tiu2) => {
                        this.PedidosCliente = tiu2.filter(x => x.cliente.clienteId == parseInt(this.ClienteId, 10)).sort(function (a, b) {
                            return a.pedidoId - b.pedidoId;
                        }).reverse();

                        //calcula valor total
                        //let valor = 0
                        //let recebe = this.PedidosCliente.listaPedidoItem;
                        //recebe.forEach((item) => {
                        //    valor += item.valorTotalProduto
                        //});

                        //let recebe2 = this.PedidosCliente.listaPedidoItemAdicional;
                        //recebe2.forEach((item) => {
                        //    valor += item.valorTotalProduto
                        //});

                        //let recebe3 = this.PedidosCliente.listaPedidoItemManual;
                        //recebe3.forEach((item) => {
                        //    valor += item.valorTotalProduto
                        //});

                        //valor += this.PedidosCliente.valorFrete

                        //this.TotalPedido = (valor - this.PedidosCliente.valorCupom)


                        //carrega Pedido endereco
                        this.$http
                            .get(this.$apiUrl + "/PedidoEndereco")
                            .then((res2) => res2.json())
                            .then((tiu2) => {
                                this.PedidosEndereco = tiu2.filter(x => x.pedido.pedidoId == parseInt(this.UltimoPedido.pedidoId, 10))

                            });

                        //carrega Pedido agendamento
                        this.$http
                            .get(this.$apiUrl + "/PedidoAgendamento")
                            .then((res2) => res2.json())
                            .then((tiu2) => {
                                this.PedidosAgendamento = tiu2.filter(x => x.pedido.pedidoId == parseInt(this.UltimoPedido.pedidoId, 10))
                            });
                    });
            }
        }
    }

</script>

<style scoped>

    @import '/css_cart/carrinho.css';
    @import '/css_cart/minha_conta.css';
</style>