<template>
    <div style="position: relative; min-height: 140px;">
        <div class="top-wrap">
            <div class="container-xl">
                <div class="row justify-content-end">
                    <div class="col-md-4 topper d-flex align-items-xl-center">
                        <div class="text">
                            <p class="con"><span>Atendimento</span> <a :href="Linktelefone" target="_blank"><span style="color:#000000;">{{telefone}}</span></a></p>
                        </div>
                    </div>
                    <div class="col-md align-items-xl-center topper" style="display: flex;">
                        <div class="text" style="padding-top: 5px;">
                            <p class="con"><span></span>
                               <!--  <span style="padding: 10px;"><router-link to="/meusPedidos">MEUS PEDIDOS</router-link></span><span>|</span> -->
                                <span style="padding: 10px;"><router-link to="/meusDados">MINHA CONTA</router-link></span><span>|</span>
                                <span style="padding: 10px;"><router-link to="/carrinho">MEU CARRINHO <i class="fa fa-shopping-cart" style="font-size: 20px;color: #BF0000;"></i></router-link></span><span></span>
                            </p>
                        </div>
                    </div>
                    <div class="col-md-2 topper d-flex align-items-xl-center justify-content-end">

                        <ul class="ftco-social">
                            <li><a v-if="Linke!='' && Linke[0].visivel" :href="Linke[0].nome" target="_blank" class="d-flex align-items-center justify-content-center"><span
                                        class="fa fa-linkedin"></span></a></li>
                            <li><a v-if="Face!='' && Face[0].visivel" :href="Face[0].nome" target="_blank" class="d-flex align-items-center justify-content-center"><span
                                        class="fa fa-facebook"></span></a></li>
                            <li><a v-if="Insta!='' && Insta[0].visivel" :href="Insta[0].nome" target="_blank" class="d-flex align-items-center justify-content-center"><span
                                        class="fa fa-instagram"></span></a></li>
                            <li><a v-if="Yout!='' && Yout[0].visivel" :href="Yout[0].nome" target="_blank" class="d-flex align-items-center justify-content-center"><span
                                        class="fa fa-youtube"></span></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <b-navbar toggleable="lg" class="navbar navbar-expand-lg  ftco-navbar-light container-xl">
            <b-navbar-brand class="navbar-brand d-flex align-items-center" href="" @click="$redirect_reload('/')" style="float:left;">
                <div class="">
                    <a href="" @click="$redirect_reload('/')">
                        <img src="/images/logo1.png" alt="" style="width: 200px;"></a>
                </div>
            </b-navbar-brand>
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse class="collapse navbar-collapse border-top-custom" id="nav-collapse" is-nav>
                <b-navbar-nav class="navbar-nav ms-auto mb-2 mb-lg-0 me-lg-5">
                    <b-nav-item class="nav-link" href="" @click="$redirect_reload('/')" style="padding-top: 0px;">Home</b-nav-item>
                    <b-nav-item class="nav-link" href="" @click="$redirect_reload('/sobre')" style="padding-top: 0px;">Quem Somos</b-nav-item>
                    <b-nav-item class="nav-link" href="" @click="$redirect_reload('/produtos')" style="padding-top: 0px;">Produtos</b-nav-item>
                    <b-nav-item class="nav-link" href="" @click="$redirect_reload('/informacoesgerais/62/COMO-COMPRAR')" style="padding-top: 0px;">Como Comprar</b-nav-item>
                   <!--  <b-nav-item class="nav-link" href="" @click="$redirect_reload('/blog')" style="padding-top: 0px;">Blog</b-nav-item> -->
                    <b-nav-item class="nav-link" href="" @click="$redirect_reload('/contato')" style="padding-top: 0px;">Contato</b-nav-item>
                </b-navbar-nav>
                <a href="#" v-if="usuario == ''" @click="$redirect_reload('/login')" class="btn-custom">Login</a>
                <a href="#" v-else @click="Logout()" class="btn-custom">Logout</a>
            </b-collapse>
        </b-navbar>
    </div>
</template>
<script>
export default {
    data() {
        return {
            usuario: {},
            WhatsFlutuante: [],
            informacoes:[],
            telefone:'',
            Linktelefone:'',
            Face: [],
                Insta: [],
                Yout: [],
                Linke:[],
        };
    },
    methods: {
        CheckLogin() {
            let usuarioTmp = {
                email: '',
                senha: '',
            }
            usuarioTmp = JSON.parse(this.$cookie.get(this.$UsuarioCookie));
            if (usuarioTmp != null) {
                let _usuario = {
                    email: usuarioTmp.email,
                    senha: usuarioTmp.senha,
                };
                this.$http.post(this.$apiUrl + "/clientes/login", _usuario).then(
                    (response) => {
                        // get body data
                        this.usuario = response.body;
                    },
                    (response2) => {
                        // error callback
                        this.$mensagem_erro(response2.body);

                    }
                );
            }
            else {
                this.usuario = '';
            }
        },
        Logout() {
            this.$cookie.delete(this.$UsuarioCookie);
            localStorage.removeItem(this.$UsuarioDados);
            this.$redirect_reload("/");
        },
    },
    created() {
        this.CheckLogin();

        this.$http
            .get(this.$apiUrl + "/institucional/telas/whatsflutuante")
            .then((res2) => res2.json())
            .then((tiu2) => {
                this.WhatsFlutuante = tiu2;
            });

            this.$http
            .get(this.$apiUrl + "/institucional/telas/informacoes")
            .then((res2) => res2.json())
            .then((tiu2) => {
                this.informacoes = tiu2;
                if (this.informacoes!=''){
                    this.telefone = this.informacoes[0].nome
                    this.Linktelefone = this.informacoes[0].referencia
                }
            });     
            
            this.$http
                .get(this.$apiUrl + "/institucional/telas/facebook")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    if (tiu2!=''){
                        this.Face = tiu2
                    }
                   
                });

            this.$http
                .get(this.$apiUrl + "/institucional/telas/instagram")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    if (tiu2!=''){
                    this.Insta = tiu2
                    }
                });

            this.$http
                .get(this.$apiUrl + "/institucional/telas/youtube")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    if (tiu2!=''){
                    this.Yout = tiu2
                    }
                });

                 this.$http
                .get(this.$apiUrl + "/institucional/telas/linkedin")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    if (tiu2!=''){
                    this.Linke = tiu2
                    }
                });

    },
    mounted() {

    },
    components: {
    },
};
</script>

<style>
/* WHATSAPP FIXO */
.whatsapp-fixo {
    position: fixed;
    bottom: 2%;
    right: 0px;
    z-index: 99999999;
}

.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: baseline !important;
}

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
</style>

