<template>

    <section class="carrinho">
    
    
        <section class="hero-wrap hero-wrap-2" style="background-image: url('/images/bg_1.jpg');height: 250px;">
            <div class="overlay"></div>
            <div class="container">
                <div class="row no-gutters slider-text justify-content-center" style="padding-top: 100px;">
                    <div class="col-md-9 mb-5 text-center">
    
                        <h1 class="mb-0 bread">Blog</h1>
                    </div>
                </div>
            </div>
        </section>
    
        <section class="ftco-section">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 blog-single">
                        <h2 class="mb-3">{{artigo_titulo}}</h2>
                        <small>{{ currentDateTime(artigo_data) }}</small>
                        <br/>
                         <img v-if="artigo_imagem!=''" :src="artigo_imagem" alt="">
                         <img v-else :src="$Url + '/imagens/indisponivel.pjg'" style="height: 100%;" alt="">
                        <p v-html="artigo_texto" class="mt-5 mb-5"></p>
                    </div> <!-- .col-md-8 -->
                </div>
                <div class="row">
                    <div class="col-lg-12 text-center mb-5">
                        <div class="view__all view__all--portfolio">
                            <a href="" @click="$redirect('/blog')">voltar</a>
                        </div>
                    </div>
                </div>
            </div>
        </section> <!-- .section -->
    
    </section>
</template>

<script>
    import moment from 'moment'

    export default {
        components: {
        },
        data() {
            return {
                artigosLateral: [],
                artigo_titulo: '',
                artigo_imagem: '',
                artigo_texto: '',
                artigo_data: '',
                artigo: {},  
            }
        },
        methods: {
            carregaArtigo(idArtigo) {
                this.$http
                    .get(this.$apiUrl + "/institucional/" + idArtigo)
                    .then((res) => res.json())
                    .then((retornoAPI) => {
                        this.artigo = retornoAPI
                        this.artigo_titulo = this.artigo.nome
                        this.artigo_texto = this.artigo.descricao1;
                        this.artigo_data = this.artigo.data1;

                        if (this.artigo.institucionalImg[0] != null) {
                            this.artigo_imagem = this.$Url + this.artigo.institucionalImg[0].urlImagem;
                        }
                        else {
                            this.artigo_imagem = '';
                        }

                    });
            },
            currentDateTime(vdate) {
                return moment(vdate).format('DD/MM/YYYY')
            },
        },
        created() {

            if (typeof this.$route.params.id != 'undefined') {
                this.carregaArtigo(this.$route.params.id);
            }
        },
        mounted() {

        },
    }

</script>

<style scoped>

</style>